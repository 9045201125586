const VALID_ID_RE = new RegExp('[\\w-]{11}');

export function extractYouTubeVideoID(url: string): string {
  if (!url) {
    fail();
  }

  return validateId(extract());

  function extract() {
    const parsedUrl = new URL(url);

    if (parsedUrl.hostname.endsWith('youtube.com')) {
      if (parsedUrl.pathname === '/watch') {
        return parsedUrl.searchParams.get('v');
      } else if (parsedUrl.pathname.startsWith('/embed')) {
        // expected value: /embed/<id>
        return parsedUrl.pathname.split('/')[2];
      } else {
        fail();
      }
    } else if (parsedUrl.hostname.endsWith('youtu.be')) {
      // expected value: /<id>
      return parsedUrl.pathname.split('/')[1];
    } else {
      fail();
    }
  }

  function fail() {
    throw new Error(`Invalid YouTube url "${url}"`);
  }

  function validateId(id: string): string {
    if (VALID_ID_RE.test(id)) return id;
    fail();
  }
}
