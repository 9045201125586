import React from 'react';

import { useAuth } from 'provider';

import { useAsync } from 'storage';

import { PayloadBase, getIntegrationHits, getSocDashboardStats } from './SocDashboard.api';
import { Hits, Stats } from './SocDashboard.type';
import { combineHitsCharts, fillHitsChart } from './SocDashboard.util';

function useSocDashboardStats(dates?: PayloadBase) {
  const { start, stop } = dates ?? {};
  const { defaultOrgId } = useAuth();
  const { data: statsData, run: statsRun, status: statsStatus, errorProps: statsErrorProps } = useAsync<Stats>();
  const { data: hitsData, run: hitsRun, status: hitsStatus, errorProps: hitsErrorProps } = useAsync<Hits[]>();

  const getStats = React.useCallback(() => {
    if (start && defaultOrgId) {
      statsRun(
        getSocDashboardStats({
          start,
          stop,
          organization_id: defaultOrgId
        })
      );
    }
  }, [defaultOrgId, statsRun, start, stop]);

  React.useEffect(() => {
    getStats();
  }, [getStats]);

  const getHits = React.useCallback(() => {
    if (start && defaultOrgId) {
      hitsRun(getIntegrationHits({ start, stop }));
    }
  }, [defaultOrgId, hitsRun, start, stop]);

  React.useEffect(() => {
    getHits();
  }, [getHits]);

  const combinedHits = React.useMemo(
    () => fillHitsChart(start, stop, combineHitsCharts(hitsData?.map(i => i.integration_hits))),
    [hitsData, start, stop]
  );

  const hitsByIntegration = React.useMemo(
    () =>
      hitsData?.reduce(
        (mappedHits, integration) => ({
          ...mappedHits,
          [integration.integration_guid]: {
            total: integration.integration_hit_count,
            hits: fillHitsChart(start, stop, integration.integration_hits)
          }
        }),
        {}
      ),
    [hitsData, start, stop]
  );

  return {
    statsData,
    statsStatus,
    statsErrorProps,
    combinedHits,
    hitsByIntegration,
    hitsData,
    hitsStatus,
    hitsErrorProps
  };
}

export default useSocDashboardStats;
