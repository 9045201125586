import React from 'react';

import isEqual from 'lodash/isEqual';
import { useLocation } from 'react-router-dom';

import { DEFAULT_MATRIX_DISPLAY } from 'module/Scaffold/Scaffold.const';
import { hydratedMatrixDisplayOptions } from 'module/Scaffold/Scaffold.service';
import { MatrixDisplay, MatrixDisplayOption, MatrixPaletteOption } from 'module/Scaffold/Scaffold.type';

import { HeatMap } from './Matrix.type';

export default function useMatrixOptions() {
  const { search } = useLocation();
  const [params, setParams] = React.useState<MatrixDisplay>({
    display: DEFAULT_MATRIX_DISPLAY,
    palette: MatrixPaletteOption.Traffic,
    heatMap: HeatMap.Detection,
    bounds: 5
  });

  React.useEffect(() => {
    const h = hydratedMatrixDisplayOptions(search);

    if (!isEqual(params, h)) {
      setParams(h);
    }
  }, [params, search]);

  return React.useMemo(
    () => ({
      showMitreId: params.display?.includes(MatrixDisplayOption.MitreID),
      showCount: params.display?.includes(MatrixDisplayOption.Count),
      showEmpty: params.display?.includes(MatrixDisplayOption.Empty),
      showSubtechniques: params.display?.includes(MatrixDisplayOption.Subtechnique),
      showPreAttack: params.display?.includes(MatrixDisplayOption.PreAttack),
      palette: params.palette,
      heatMap: params.heatMap,
      maxRange: params.bounds,
      perBucket: 1
    }),
    [params]
  );
}
