import moment from 'moment';

import { ArtifactScore } from 'types/common';

import { RadarData } from './SocDashboard.type';

export const DEMO_INTEGRATION = [
  {
    name: 'CrowdStrike',
    guid: '00000000-0000-0000-0000-000000000000',
    integration_id: -1,
    deployments: 114,
    hits: { total: 318, hits: [] },
    score: 0.9,
    languageIds: [-1],
    confidenceData: [
      {
        name: ArtifactScore.HIGHEST,
        value: 72
      },
      {
        name: ArtifactScore.HIGH,
        value: 35
      },
      {
        name: ArtifactScore.MEDIUM,
        value: 7
      },
      {
        name: ArtifactScore.LOW,
        value: 0
      },
      {
        name: ArtifactScore.LOWEST,
        value: 0
      }
    ],
    validatedDetections: [
      {
        name: 'Validated',
        value: 94
      },
      {
        name: 'Unvalidated',
        value: 13
      },
      {
        name: 'Untested',
        value: 7
      }
    ],

    detectionData: [
      {
        value: 4
      },
      {
        value: 5
      },
      {
        value: 5.5
      },
      {
        value: 6
      },
      {
        value: 6.5
      },
      {
        value: 7
      }
    ]
  },
  {
    name: 'Google Chronicle',
    guid: '00000000-0000-0000-0000-000000000000',
    integration_id: -1,
    deployments: 103,
    hits: { total: 357, hits: [] },
    score: 0.9,
    languageIds: [-1],
    confidenceData: [
      {
        name: ArtifactScore.HIGHEST,
        value: 79
      },
      {
        name: ArtifactScore.HIGH,
        value: 31
      },
      {
        name: ArtifactScore.MEDIUM,
        value: 5
      },
      {
        name: ArtifactScore.LOW,
        value: 2
      },
      {
        name: ArtifactScore.LOWEST,
        value: 0
      }
    ],
    validatedDetections: [
      {
        name: 'Validated',
        value: 91
      },
      {
        name: 'Unvalidated',
        value: 15
      },
      {
        name: 'Untested',
        value: 4
      }
    ],

    detectionData: [
      {
        value: 4
      },
      {
        value: 5
      },
      {
        value: 5.5
      },
      {
        value: 6
      },
      {
        value: 6.5
      },
      {
        value: 7
      }
    ]
  }
];

export const DEMO_RISK_MATRIX = [
  [0, 0, 0, 0, 0, 0],
  [0, 26, 0, 0, 115, 0],
  [0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0],
  [0, 4, 0, 0, 47, 0],
  [0, 0, 0, 0, 0, 0]
];

export const DEMO_COLOR_KEY = {
  'Google Chronicle': 0,
  Crowdstrike: 1
};

export const DEMO_ATTACK_COVERAGE: RadarData[] = [
  {
    name: 'Defense Evasion',
    values: {
      'Google Chronicle': 89,
      Crowdstrike: 76
    }
  },
  {
    name: 'Discovery',
    values: {
      'Google Chronicle': 91,
      Crowdstrike: 86
    }
  },
  {
    name: 'Impact',
    values: {
      'Google Chronicle': 97,
      Crowdstrike: 82
    }
  },
  {
    name: 'Privilege Escalation',
    values: {
      'Google Chronicle': 99,
      Crowdstrike: 79
    }
  },
  {
    name: 'Persistence',
    values: {
      'Google Chronicle': 93,
      Crowdstrike: 89
    }
  },
  {
    name: 'Collection',
    values: {
      'Google Chronicle': 95,
      Crowdstrike: 78
    }
  },
  {
    name: 'Resource Development',
    values: {
      'Google Chronicle': 87,
      Crowdstrike: 93
    }
  },
  {
    name: 'Reconnaissance',
    values: {
      'Google Chronicle': 84,
      Crowdstrike: 91
    }
  },
  {
    name: 'Command and Control',
    values: {
      'Google Chronicle': 88,
      Crowdstrike: 76
    }
  },
  {
    name: 'Exfiltration',
    values: {
      'Google Chronicle': 93,
      Crowdstrike: 87
    }
  },
  {
    name: 'Initial Access',
    values: {
      'Google Chronicle': 93,
      Crowdstrike: 91
    }
  },
  {
    name: 'Lateral Movement',
    values: {
      'Google Chronicle': 88,
      Crowdstrike: 92
    }
  },
  {
    name: 'Execution',
    values: {
      'Google Chronicle': 90,
      Crowdstrike: 80
    }
  },
  {
    name: 'Credential Access',
    values: {
      'Google Chronicle': 86,
      Crowdstrike: 89
    }
  }
];

export const EMPTY_ATTACK_COVERAGE: RadarData[] = [
  {
    name: 'Defense Evasion',
    values: {
      '': 0
    }
  },
  {
    name: 'Discovery',
    values: {
      '': 0
    }
  },
  {
    name: 'Impact',
    values: {
      '': 0
    }
  },
  {
    name: 'Privilege Escalation',
    values: {
      '': 0
    }
  },
  {
    name: 'Persistence',
    values: {
      '': 0
    }
  },
  {
    name: 'Collection',
    values: {
      '': 0
    }
  },
  {
    name: 'Resource Development',
    values: {
      '': 0
    }
  },
  {
    name: 'Reconnaissance',
    values: {
      '': 0
    }
  },
  {
    name: 'Command and Control',
    values: {
      '': 0
    }
  },
  {
    name: 'Exfiltration',
    values: {
      '': 0
    }
  },
  {
    name: 'Initial Access',
    values: {
      '': 0
    }
  },
  {
    name: 'Lateral Movement',
    values: {
      '': 0
    }
  },
  {
    name: 'Execution',
    values: {
      '': 0
    }
  },
  {
    name: 'Credential Access',
    values: {
      '': 0
    }
  }
];

export const DEMO_SOC_CAPACITY = [
  {
    value: 75,
    date: moment().subtract(35, 'd').format('MM/DD/yyyy')
  },
  {
    value: 92,
    date: moment().subtract(28, 'd').format('MM/DD/yyyy')
  },
  {
    value: 117,
    date: moment().subtract(21, 'd').format('MM/DD/yyyy')
  },
  {
    value: 88,
    date: moment().subtract(14, 'd').format('MM/DD/yyyy')
  },
  {
    value: 114,
    date: moment().subtract(7, 'd').format('MM/DD/yyyy')
  },
  {
    value: 96,
    date: moment().format('MM/DD/yyyy')
  }
];

export const DEMO_THRESHOLD = 100;
