import React from 'react';

import { Chip } from '@mui/material';
import classNames from 'classnames';

import { ExternalLink } from 'snap-ui/Link';
import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

import config from 'config/config';

const commitHash = new RegExp(/[a-f0-9]{8}$/, 'i');
const version = commitHash.test(config.VERSION) ? config.VERSION.slice(-8, -1) : config.VERSION;
// this includes chrome specific "text fragments" to search and jump to text on that page
const githubLink = 'https://github.com/snapattack/snapattack/commits/develop#:~:text=';
const releaseNotes = 'https://docs.snapattack.com/releases/release-notes/';

const CopyrightContainer = styled('div')`
  margin-top: ${p => p.theme.spacing(4)};
  text-align: center;
  color: ${p => p.theme.palette.grey[600]};
`;

const StyledIframe = styled('iframe')`
  max-width: 250px;
  height: 30px;
  margin-bottom: ${p => p.theme.spacing(4)};
  padding: 0 5px;
  border: none;
  overflow: hidden;
  color-scheme: normal;
`;

function Copyright({ className, withStatus = false }: { className?: string; withStatus?: boolean }) {
  return (
    <CopyrightContainer className={classNames('Copyright', className)}>
      {withStatus && <StyledIframe src='https://status.snapattack.com/badge?theme=dark' />}
      <Typography variant='subtitle2' component='div'>
        © 2021-{new Date().getFullYear()} Threatology, Inc.
        <br />
        <ExternalLink icon href={commitHash.test(config.VERSION) ? githubLink + version : releaseNotes}>
          Version {version}
        </ExternalLink>
      </Typography>
    </CopyrightContainer>
  );
}

export default Copyright;

const StyledChip = styled(Chip)`
  position: absolute;
  bottom: 10px;
  right: 10px;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;

  &:hover {
    opacity: 1;
  }
`;

export function CopyrightBadge() {
  return (
    <ExternalLink href={releaseNotes}>
      <StyledChip color='primary' label={`Version ${version}`} />
    </ExternalLink>
  );
}
