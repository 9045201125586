import React from 'react';

import classnames from 'classnames';

import { styled } from './util';

export { default } from '@mui/material/Grid';

const StyledGrid = styled('div', { shouldForwardProp: p => p === 'className' || p === 'children' })<AutoflowGridProps>`
  /* User input values */
  --grid-layout-gap: ${p => p.gap};
  --grid-column-count: ${p => p.maxColumns};
  --grid-item--min-width: ${p => p.minWidth};

  /* Calculated values */
  --gap-count: calc(var(--grid-column-count) - 1);
  --total-gap-width: calc(var(--gap-count) * var(--grid-layout-gap));
  --grid-item--max-width: calc((100% - var(--total-gap-width)) / var(--grid-column-count));

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(max(var(--grid-item--min-width), var(--grid-item--max-width)), 1fr));
  grid-gap: var(--grid-layout-gap);
`;

type AutoflowGridProps = {
  className?: string;
  maxColumns: number;
  minWidth: string;
  gap: string;
};
export function AutoflowGrid({
  children,
  className,
  maxColumns,
  minWidth,
  gap
}: React.PropsWithChildren<AutoflowGridProps>): JSX.Element {
  return (
    <StyledGrid className={classnames('AutoflowGrid', className)} maxColumns={maxColumns} minWidth={minWidth} gap={gap}>
      {children}
    </StyledGrid>
  );
}
