import React from 'react';

import { JobGroupResult, useJobGroupResult } from 'module/Job';

import { ErrorProps, Status, useAsync } from 'storage';

import { Guid } from 'types/common';

import { postBulkConfidence } from './BulkConfidence.api';
import { BulkConfidencePayload } from './BulkConfidence.type';

type BulkConfidenceInterface = {
  data: JobGroupResult[];
  getResults(): void;
  isPending: boolean;
  errorProps: ErrorProps;
  updateBulkConfidence(payload: BulkConfidencePayload[], overwriteManual?: boolean): Promise<void>;
};

export default function useBulkConfidence(guid: Guid): BulkConfidenceInterface {
  const { result: data, refresh, status, errorProps } = useJobGroupResult(guid);
  const { task, status: updateStatus, errorProps: jobGroupErrorProps } = useAsync<JobGroupResult[]>([]);

  const updateBulkConfidence = React.useCallback(
    (payload: BulkConfidencePayload[], overwriteManual?: boolean) => {
      return task(postBulkConfidence(payload, overwriteManual));
    },
    [task]
  );

  return {
    data,
    getResults: refresh,
    isPending: [status, updateStatus].includes(Status.pending),
    updateBulkConfidence,
    errorProps: jobGroupErrorProps || errorProps
  };
}
