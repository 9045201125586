import React from 'react';

import { GridRenderEditCellParams, useGridApiContext } from 'snap-ui/DataGrid';

import { SigmaTag, useSearchAllTags } from 'module/Tag';

import { SelectCell } from './Blacklist.style';

const USE_ALIASES_AND_NAMES = false;
type Option = { label: string; value: string };
function excludeDuplicatesAndNotMatching(value: SigmaTag, index: number, self: SigmaTag[], comparison: string[]) {
  return (
    index === self.findIndex(t => t.name === value.name) && value.sigma_names.some(name => comparison?.includes(name))
  );
}

function insensitiveSort(a: Option, b: Option) {
  return a.label.toLowerCase().localeCompare(b.label.toLowerCase());
}

export function SearchAliasesEditCell(
  props: GridRenderEditCellParams & {
    isValidValueField: boolean;
    onChange(aliasSelected: boolean): void;
    sigmaTags: string[];
  }
) {
  const { field, id, isValidValueField, onChange, value: alias, sigmaTags } = props;
  const apiRef = useGridApiContext();
  const { data, search, reset } = useSearchAllTags({ onlyNames: USE_ALIASES_AND_NAMES });

  const stringSigmaTags = sigmaTags?.toString();
  const options = React.useMemo(
    () =>
      data
        .filter((v, i, s) => excludeDuplicatesAndNotMatching(v, i, s, sigmaTags))
        .map(d => ({
          content: d.name,
          label: d.name,
          value: d.name
        }))
        .sort(insensitiveSort),
    [data, stringSigmaTags] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleChange = (value: string) => {
    apiRef.current.setEditCellValue({ id, field, value });
    onChange(value.length > 0);
  };

  React.useEffect(() => {
    if (isValidValueField) search(props.row.value);
    else reset();
  }, [isValidValueField, reset, search, props.row.value]);

  return <SelectCell disabled={!isValidValueField} value={alias} options={options} onChange={handleChange} />;
}
