import padStart from 'lodash/padStart';
import moment from 'moment-timezone';

export const FULL_TIMESTAMP = 'YYYY-MM-DD HH:mm z';
export const SHORT_TIMESTAMP = 'MMM Do, YYYY [at] h:mm A z';
export const MINI_TIMESTAMP = 'MMM Do, YYYY h:mmA';
export const DAY_TIMESTAMP = 'MMM Do, YYYY';
export const STANDARD = 'MM/DD/YYYY';

export type ParsableDate = Date | moment.Moment | string;

export enum TimeInSeconds {
  minute = 60,
  twoMinutes = 120,
  fiveMinutes = 300,
  fifteenMinutes = 900,
  thirtyMinutes = 1800,
  hour = 3_600,
  fourHours = 14_400,
  sixHours = 21_600,
  eightHours = 28_800,
  twelveHours = 43_200,
  day = 86_400,
  sevenDays = 604_800,
  fourteenDays = 1_209_600,
  thirtyDays = 2_592_000,
  oneMonth = 2_678_400, // 31 days
  ninetyDays = 7_776_000,
  hundredEightyDays = 15_552_000,
  oneYear = 31_536_000
}

export const ISO_ZERO_DURATION = 'PT0S';

export const convertFormattedTimeToSeconds = (time: string): number => {
  return time.split(':').reduce((acc: number, time: string) => 60 * acc + +time, 0);
};

export const convertSecondsToMMss = (totalSeconds: number) => {
  const dur = moment.duration(totalSeconds, 'seconds');
  const seconds = padStart(dur.seconds().toString(), 2, '0');
  const minutes = dur.minutes();
  const hours = dur.hours();
  if (hours) {
    return `${hours}:${padStart(minutes.toString(), 2, '0')}:${seconds}`;
  }
  return `${padStart(minutes.toString(), 2, '0')}:${seconds}`;
};

export const convertMillisecondsToMMss = (totalMs: number) => {
  const dur = moment.duration(Math.max(totalMs, 0), 'milliseconds');
  const seconds = padStart(dur.seconds().toString(), 2, '0');
  const minutes = dur.minutes();
  const hours = dur.hours();
  if (hours) {
    return `${hours}:${padStart(minutes.toString(), 2, '0')}:${seconds}`;
  }
  return `${padStart(minutes.toString(), 2, '0')}:${seconds}`;
};

export function formatCustomNow(format: string) {
  return moment(Date.now()).format(format);
}

export const formatCustomTimestamp = (timestamp: string | number | moment.Moment, format: string) => {
  return timestamp ? moment(timestamp).format(format) : '';
};

export const formatFullTimestamp = (timestamp: number | moment.Moment) => {
  return timestamp ? moment(timestamp).format(FULL_TIMESTAMP) : '';
};

export const formatFullIsoTimestamp = (timestamp: number | moment.Moment) => {
  return timestamp ? moment(timestamp).toISOString() : '';
};

export function formatShortTimestamp(timestamp: string | Date): string {
  return timestamp ? convertTimezone(timestamp).format(SHORT_TIMESTAMP) : '';
}

export function formatMiniTimestamp(timestamp: string): string {
  return timestamp ? convertTimezone(timestamp).format(MINI_TIMESTAMP) : '';
}
export function formatDayTimestamp(timestamp: string): string {
  return timestamp ? convertTimezone(timestamp).format(DAY_TIMESTAMP) : '';
}

export function formatExactDayTimestamp(timestamp: string): string {
  return timestamp ? moment(timestamp).format(DAY_TIMESTAMP) : '';
}

export function timeAgo(timestamp: string | Date): string {
  return timestamp ? moment(timestamp).tz(moment.tz.guess()).fromNow() : '';
}

export function convertTimezone(timestamp: string | Date): moment.Moment {
  return moment(timestamp).tz(moment.tz.guess());
}

export const minutesBetween = (endTime: number, startTime: number) => {
  if (!endTime || !startTime) return '-';
  const diff = moment(endTime).diff(moment(startTime), 'minutes');
  return diff < 1 ? '< 1 min' : diff.toString().concat(' min');
};

export const mmSSBetween = (endTime: number, startTime: number) => {
  if (!endTime || !startTime) return '-';
  const diff = moment(endTime).diff(moment(startTime), 'seconds');
  return convertSecondsToMMss(diff);
};

export const daysBetween = (startDate: Date, stopDate: Date) => {
  const momentStart = moment(startDate).startOf('day');
  const momentStop = moment(stopDate).endOf('day');
  return momentStop.diff(momentStart, 'days');
};

export const humanTimeBetween = (startDate: Date, stopDate: Date) => {
  if (!startDate || !stopDate) return null;
  const diff = moment(stopDate).diff(moment(startDate));
  const duration = moment.duration(diff);
  if (duration.asMinutes() < 60) {
    return Math.round(duration.asMinutes()) + ' minutes';
  } else if (duration.asHours() < 24) {
    return Math.round(duration.asHours()) + ' hours';
  }
  return Math.round(duration.asDays()) + ' days';
};

/** @returns ISO8601 formatted duration */
export const monthsToISODuration = (duration: number): string => {
  return moment.duration(duration, 'months').toISOString();
};

/** @param duration expected to be in ISO8601 format */
export const durationToMonths = (duration: string): number => {
  return Math.round(moment.duration(duration).asMonths());
};

/** @returns ISO8601 formatted duration */
export const secondsToISODuration = (duration: number): string => {
  return moment.duration(duration, 'seconds').toISOString();
};

/** @param duration expected to be in ISO8601 format */
export const durationToSeconds = (duration: string): number => {
  return Math.round(moment.duration(duration).asSeconds());
};

export function getDiff(eventTimestamp: string, startTimestamp: string): number {
  const eventStamp = tryToGetDate(eventTimestamp).getTime();
  const startStamp = tryToGetDate(startTimestamp).getTime();
  return (eventStamp - startStamp) / 1000; // ms to s conversion
}

export function addDuration(startTimestamp: string, duration: string | number) {
  if (typeof duration === 'string') duration = convertFormattedTimeToSeconds(duration);

  return formatFullTimestamp(moment(startTimestamp).add(duration, 's'));
}

export function addDurationISOFormatted(startTimestamp: string, duration: string | number) {
  if (typeof duration === 'string') duration = convertFormattedTimeToSeconds(duration);

  return formatFullIsoTimestamp(moment(startTimestamp).add(duration, 's'));
}

export function tryToGetDate(value: string): Date {
  const milliseconds = Date.parse(value);
  return isNaN(milliseconds) ? new Date() : new Date(milliseconds);
}
/* formats a timestamp if the string is a date, otherwise returns the string */
export function attemptToFormatDate(value: string): string {
  var date = new Date(value);
  if (date instanceof Date && !isNaN(date.valueOf())) {
    return formatShortTimestamp(value);
  }
  return value;
}

export function transformISOtoHumanizeFormat(timestamp: string) {
  return moment(timestamp).format('llll');
}

export function transformSecondsToHumanFormat(
  seconds: number,
  minUnit: 'hours' | 'minutes' | 'seconds' = 'seconds',
  abbreviate = false
): string {
  // Return nothing for zero, rather than round up
  if (!seconds) {
    return '';
  }

  // Round up if the number of seconds is greater than 0 but less than 1
  if (seconds > 0 && seconds < 1) {
    seconds = 1;
  }

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.floor(seconds % 60);

  if (minUnit === 'hours' && hours === 0) {
    return abbreviate ? '1h' : '1 hour';
  } else if (minUnit === 'minutes' && hours === 0 && minutes === 0) {
    return abbreviate ? '1m' : '1 minute';
  }

  let timeString = '';
  if (hours > 0 && (minUnit === 'hours' || minUnit === 'minutes' || minUnit === 'seconds')) {
    timeString += abbreviate ? `${hours}h, ` : `${hours} ${hours === 1 ? 'hour' : 'hours'}, `;
  }
  if (minutes > 0 && (minUnit === 'minutes' || minUnit === 'seconds')) {
    timeString += abbreviate ? `${minutes}m, ` : `${minutes} ${minutes === 1 ? 'minute' : 'minutes'}, `;
  }
  if (remainingSeconds > 0 && minUnit === 'seconds') {
    timeString += abbreviate
      ? `${remainingSeconds}s`
      : `${remainingSeconds} ${remainingSeconds === 1 ? 'second' : 'seconds'}`;
  }

  // Remove trailing comma and space if present
  return timeString.replace(/,\s*$/, '');
}

/**
 * @param delta ISO8061 Time Delta
 */
export function getMomentFromDelta(delta: string): moment.Moment {
  return moment().subtract(delta);
}

/**
 * generate a list of moments from `start` to `stop` using the `delta` provided
 *
 * @param start the first point in the list
 * @param stop the last point in the list
 * @param delta ISO8061 Time Delta
 */
export function getPoints(start: ParsableDate, stop: ParsableDate, delta = 'P1D'): moment.Moment[] {
  const first = moment(start);
  const last = moment(stop);
  const lastValue = last.unix();
  let current = first.clone();
  const points = [first];

  while (current.unix() < lastValue) {
    const next = current.clone().add(delta);
    if (next.unix() < lastValue) {
      points.push(next);
    } else {
      points.push(last);
    }
    current = next;
  }

  return points;
}
