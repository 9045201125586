import React from 'react';

import classnames from 'classnames';
import { useField } from 'formik';

import { AutocompleteFormikProps } from 'module/Form/AutocompleteFormik';

import { Discriminator } from '.';
import TagSigmaAutocomplete from './TagSigmaAutocomplete';
import { TagOption } from './useTagOptions';

export type Props = Omit<AutocompleteFormikProps, 'options' | 'label' | 'placeholder' | 'disableUserAdditions'> & {
  discriminator: Discriminator;
  enableUserAdditions?: boolean;
};

export default function TagAutocompleteFormik({
  className,
  disabled,
  discriminator,
  name,
  required,
  ...others
}: Props) {
  const [field, meta, helpers] = useField(name);

  function handleChange(value: TagOption[]) {
    const sigmaValues = value.map(v => v.value);

    helpers.setValue(sigmaValues);
  }

  return (
    <TagSigmaAutocomplete
      className={classnames(className, { required, valid: meta.touched && !meta.error })}
      includeTagNames
      disabled={disabled}
      discriminator={discriminator}
      error={!!meta.error}
      helperText={meta.error}
      name={name}
      onChange={handleChange}
      testId={discriminator}
      value={field.value}
      {...others}
    />
  );
}
