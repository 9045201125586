import React from 'react';

import isEmpty from 'lodash/isEmpty';
import { RouteComponentProps } from 'react-router-dom';

import {
  DataGrid,
  GRID_AGGREGATION_FUNCTIONS,
  GridColDef,
  GridFilterModel,
  GridSortModel,
  GridValueGetterParams,
  gridStringOrNumberComparator,
  useGridApiRef,
  useKeepGroupedColumnsHidden
} from 'snap-ui/DataGrid';
import { getRowForSort } from 'snap-ui/DataGrid/DatGrid.helper';
import Typography from 'snap-ui/Typography';

import Path from 'constants/paths';

import useTitle from 'hooks/useTitle';

import { useMayI } from 'module/May';
import EmptyState from 'module/Widgets/EmptyState';
import TablePagination from 'module/Widgets/TablePagination';

import { useAuth, useManagedOrganizations, useUserConfig } from 'provider';

import { Status } from 'storage';

import { FunctionalPermission } from 'types/auth';
import { Guid } from 'types/common';

import { pageTotal as getPageTotal } from 'utilities/NumberUtil';

import { COLUMN_MODEL_JOB_OVERVIEW } from '../Job.const';
import { JobOverview, JobType } from '../Job.type';
import { convertSortModelToQueryString, getGridColumnsTask, getRowId } from '../Job.util';
import { JobGridTreeDataGroupingCell, TaskTableToolbar } from '../Job.widgets';
import useJobOverview from '../useJobOverview';
import {
  aggregateCreated,
  aggregateCreatedBy,
  aggregateIntegration,
  aggregateOrg,
  aggregateProgress,
  aggregateStatus,
  aggregateTimeRemaining,
  aggregateType,
  attackScripts,
  created_by,
  creation,
  detections,
  hits,
  integrationNames,
  jobGuid,
  jobName,
  jobsByGroup,
  modified,
  organization,
  progress,
  sumUp,
  taskActions,
  taskStatus,
  timeRemaining
} from './Column';
import { Container, GridSizer } from './Task.style';
import useTaskReducer from './useTaskReducer';

type TaskProps = RouteComponentProps<{ id: Guid }>;

const JOB_TYPES = [JobType.Hunt, JobType.IOC, JobType.Rank, JobType.Import];
const DEFAULT_JOB_TYPE = JOB_TYPES[0];

export default function Task(props: TaskProps) {
  useTitle('Tasks | SnapAttack');
  const { columnModel, setColumnModel } = useUserConfig();
  const { user } = useAuth();
  const isDetectionFeaturesUser = useMayI(FunctionalPermission.DetectionHuntFeatures);
  const isSimulateUser = useMayI(FunctionalPermission.SimulationFeatures);

  const jobTypes = isSimulateUser ? [...JOB_TYPES, JobType.Simulate] : JOB_TYPES;

  const [state, dispatch] = useTaskReducer(DEFAULT_JOB_TYPE);
  const { typeFilter, orgFilter, nameFilter, sortModel, pageParams } = state;

  const guid = props.match.params.id;
  const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
    items: [],
    quickFilterValues: []
  });

  const jobTypeToFetch = React.useMemo(() => [typeFilter], [typeFilter]);

  const filters = React.useMemo(
    () => ({
      sort: convertSortModelToQueryString(sortModel),
      org_id: orgFilter,
      name: nameFilter
    }),
    [nameFilter, orgFilter, sortModel]
  );

  const {
    items: jobs,
    status: jobsStatus,
    total,
    deleteJobs,
    pause,
    resume,
    cancel,
    taskStatus: actionTaskStatus
  } = useJobOverview(jobTypeToFetch, pageParams, filters);

  const pageTotal = getPageTotal(total, pageParams.size);
  const { organizations } = useManagedOrganizations();

  const apiRef = useGridApiRef();

  const isLoading = jobsStatus === Status.pending || actionTaskStatus === Status.pending;

  const filteredJobs = React.useMemo(() => {
    return (
      jobs
        ?.filter(job => {
          if (typeFilter !== DEFAULT_JOB_TYPE) return typeFilter.includes(job.type as JobType);
          else return true;
        })
        .filter(job => (orgFilter === null ? job : job.organization_id === orgFilter)) || []
    );
  }, [jobs, typeFilter, orgFilter]);

  const taskActionsProps = React.useMemo(
    () => ({
      isDetectionFeaturesUser,
      deleteJobs,
      pause,
      resume,
      cancel,
      apiRef
    }),
    [apiRef, cancel, deleteJobs, isDetectionFeaturesUser, pause, resume]
  );

  const columns = React.useMemo(() => {
    return typeFilter === JobType.Simulate
      ? [jobsByGroup, created_by, integrationNames, creation, taskStatus(apiRef), detections, attackScripts]
      : [
          jobsByGroup,
          jobGuid(user.superuser),
          created_by,
          integrationNames,
          taskStatus(apiRef),
          progress,
          detections,
          hits,
          timeRemaining,
          creation,
          modified,
          organization(organizations),
          taskActions(taskActionsProps),
          jobName
        ];
  }, [apiRef, organizations, taskActionsProps, typeFilter, user.superuser]);

  const handleSetColumn = (value: Record<string, boolean>) =>
    setColumnModel({ ...columnModel, [COLUMN_MODEL_JOB_OVERVIEW]: value });

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      rowGrouping: {
        model: ['jobsByGroup']
      },
      filter: {
        filterModel: {
          items: []
        }
      },
      sorting: {
        sortModel: sortModel
      },
      aggregation: {
        model: {
          created_by: 'aggregateCreatedBy',
          type: 'aggregateType',
          integration_name: 'aggregateIntegration',
          job_status: 'aggregateStatus',
          progress_percent: 'aggregateProgress',
          detections_completed: 'sumUp',
          total_hits: 'sumUp',
          estimated_remaining_duration: 'aggregateTimeRemaining',
          creation: 'aggregateCreated',
          modified: 'aggregateCreated',
          organization_id: 'aggregateOrg'
        }
      }
    }
  });

  function handleChangePage(newPage: number): void {
    dispatch({ type: 'SET_PAGE', payload: newPage });
  }

  function handleSortModelChange(newModel: GridSortModel) {
    dispatch({ type: 'SET_SORT_MODEL', payload: newModel });
  }

  function onOrgChange(org: number) {
    dispatch({ type: 'SET_ORG_FILTER', payload: org });
  }

  function onSearchChange(event: React.ChangeEvent<HTMLInputElement>) {
    dispatch({ type: 'SET_NAME_FILTER', payload: event.target.value });
  }

  function onTypeChange(type: JobType) {
    dispatch({ type: 'SET_TYPE', payload: type });
  }

  return (
    <Container>
      <Typography variant='h1'>Tasks</Typography>
      <Typography variant='subtitle2' className='sub-text'>
        Tasks shown occurred within the past 30 days.
      </Typography>
      <GridSizer>
        <DataGrid
          columns={columns}
          rows={filteredJobs}
          columnVisibilityModel={{
            creation: typeFilter === JobType.Simulate,
            estimated_remaining_duration: false,
            progress_percent: false,
            analytic_job_guid: user.superuser ? true : false,
            organization_id: user.superuser ? true : false,
            ...(isEmpty(columnModel[COLUMN_MODEL_JOB_OVERVIEW]) ? undefined : columnModel[COLUMN_MODEL_JOB_OVERVIEW]),
            jobsByGroup: false,
            name: false
          }}
          onColumnVisibilityModelChange={handleSetColumn}
          loading={isLoading}
          getRowId={getRowId}
          filterModel={filterModel}
          onFilterModelChange={newModel => setFilterModel(newModel)}
          getAggregationPosition={groupNode => (groupNode == null ? null : 'inline')}
          aggregationFunctions={{
            ...GRID_AGGREGATION_FUNCTIONS,
            aggregateType,
            aggregateIntegration,
            aggregateCreatedBy,
            aggregateStatus,
            aggregateProgress,
            aggregateTimeRemaining,
            aggregateCreated,
            sumUp,
            aggregateOrg
          }}
          groupingColDef={{
            headerName: 'Name',
            flex: 1,
            minWidth: 300,
            sortingOrder: ['asc', 'desc'],
            sortComparator: (v1, v2, param1, param2) => gridStringOrNumberComparator(v1, v2, param1, param2),
            valueGetter(params: GridValueGetterParams) {
              const row = getRowForSort<JobOverview>(apiRef.current, params);
              return row?.name;
            },
            renderCell: params => (
              <JobGridTreeDataGroupingCell
                {...params}
                idPath='analytic_job_group_guid'
                linkPath={typeFilter === JobType.Simulate ? null : Path.Hunt}
                matchingGuid={guid}
              />
            )
          }}
          apiRef={apiRef}
          initialState={initialState}
          slots={{
            toolbar: TaskTableToolbar,
            footer: () => (
              <TablePagination changePage={handleChangePage} numPages={pageTotal} page={pageParams.page} zeroIndex />
            ),
            noRowsOverlay: EmptyState
          }}
          slotProps={{
            toolbar: {
              orgFilter,
              jobTypes,
              jobTypeFilter: typeFilter,
              onSearchChange,
              onTypeChange,
              onOrgChange,
              includeExport: false
            },
            noRowsOverlay: {
              title: 'No Results',
              children: 'There are no tasks available'
            },
            columnsPanel: {
              getTogglableColumns: (columns: GridColDef[]) => getGridColumnsTask(columns, user.superuser)
            }
          }}
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
        />
      </GridSizer>
    </Container>
  );
}
