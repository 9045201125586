import { CancelTokenType, snapattack } from 'apis';

import { Named } from 'types/common';

import {
  ActorCreationPayload,
  Landing,
  LandingFragment,
  LandingStats,
  LandingStatsCatalog,
  SoftwareCreationPayload
} from './Landing.type';

const prefix = '/tags';

/*
 * This endpoint will return data with a 402 'payment required' code for community users
 */
export function getNamedLanding(landing: LandingFragment, name: string, rollup: boolean): Promise<Landing> {
  if (landing === 'attacks')
    return snapattack
      .get(`${prefix}/${landing}/${name}/landing/?rollup_children=${rollup}`)
      .then(res => res.data)
      .catch(error => {
        if (error.response?.status === 402) return error.response.data;
        else throw error;
      });
  return snapattack
    .get(`${prefix}/${landing}/${name}/landing/`)
    .then(res => res.data)
    .catch(error => {
      if (error.response?.status === 402) return error.response.data;
      else throw error;
    });
}

export function getNamedLandingStatsCount(landing: LandingFragment, name: string): Promise<LandingStats> {
  return snapattack.get(`${prefix}/${landing}/${name}/landing/count/`).then(res => res.data);
}

export function getBulkNamedLandingStatsCount(
  landing: LandingFragment,
  names: string[],
  config?: { cancelToken?: CancelTokenType }
): Promise<Record<string, LandingStats>> {
  return snapattack.post(`${prefix}/${landing}/landing/counts/`, { names }, config).then(r =>
    r.data.reduce(
      (p: LandingStatsCatalog, c: LandingStats & Named) => ({
        ...p,
        [c.name]: c
      }),
      {}
    )
  );
}

export function postNewActor(payload: Partial<ActorCreationPayload>): Promise<{ id: number }> {
  return snapattack.post(`${prefix}/actors/`, payload).then(res => res.data);
}

export function updateActor(id: number, payload: Partial<ActorCreationPayload>): Promise<{ id: number }> {
  return snapattack.put(`${prefix}/actors/${id}/`, payload).then(res => res.data);
}

export function postNewSoftware(payload: Partial<SoftwareCreationPayload>): Promise<{ id: number }> {
  return snapattack.post(`${prefix}/software/`, payload).then(res => res.data);
}

export function updateSoftware(id: number, payload: Partial<ActorCreationPayload>): Promise<{ id: number }> {
  return snapattack.put(`${prefix}/software/${id}/`, payload).then(res => res.data);
}
