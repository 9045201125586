import React from 'react';

import { getEmailFromInviteToken } from 'apis/resources/auth';
import { getRegistrationMethod, registerUser, resendVerificationRequest } from 'apis/resources/user';

import { ErrorProps, Status, useAsync, useMountedRef } from 'storage';

import { RegisterRequest, RegisterResponse, RegistrationMethod } from 'types/auth';

interface RegistrationInterface {
  registrationMethod: RegistrationMethod;
  tokenToEmail: (token: string) => Promise<string>;
  resendVerificationRequest: (email: string) => Promise<void>;

  register: (data: RegisterRequest) => void;
  status: Status;
  data: RegisterResponse;
  error: any;
  errorProps: ErrorProps;
}

export default function useRegistration(): RegistrationInterface {
  const mounted = useMountedRef();
  const [registrationMethod, setRegistrationMethod] = React.useState<RegistrationMethod>();
  const { data, run, status, error, errorProps } = useAsync<RegisterResponse>();

  React.useEffect(() => {
    getRegistrationMethod()
      .then(r => mounted.current && setRegistrationMethod(r))
      .catch(() => {
        // err on the side of being too restrictive
        mounted.current && setRegistrationMethod(RegistrationMethod.Invite);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const value = React.useMemo(
    () => ({
      registrationMethod,
      tokenToEmail: (token: string) => getEmailFromInviteToken(token),
      register: (data: RegisterRequest) => run(registerUser(data), true),
      resendVerificationRequest: (email: string) => resendVerificationRequest(email)
    }),
    [registrationMethod, run]
  );

  return {
    ...value,
    data,
    status,
    error,
    errorProps
  };
}
