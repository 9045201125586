import React, { ReactElement } from 'react';

import { Formik, FormikProps } from 'formik';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { object, string } from 'yup';

import Alert, { AlertTitle } from 'snap-ui/Alert';
import BackdropLoader from 'snap-ui/BackdropLoader';
import { ExternalLink } from 'snap-ui/Link';

import { asValidationError } from 'apis/snapattack';

import Path from 'constants/paths';

import useLocationState from 'hooks/useLocationState';
import useNoPermitCreateRedirect from 'hooks/useNoPermitCreateRedirect';
import useOrganizationOptions from 'hooks/useOrganizationOptions';
import useTitle from 'hooks/useTitle';

import { Engage, Fingerprint } from 'lib/Engagement';

import { ApiError } from 'module/ApiError';
import { standardFormikBaseProps } from 'module/Form';
import { extractIndicatorsFromText } from 'module/IOC/IOC.api';
import { IOC } from 'module/IOC/IOC.type';
import useAnnotateIOCs from 'module/IOC/IOCParser/useAnnotateIOCs';
import { createNewIntel, updateIntel } from 'module/Intel/Intel.service';
import { AlertError } from 'module/Layout/Artifact.widgets';
import { Discriminator } from 'module/Tag';
import NotFound from 'module/Util/Fallback/NotFound';
import { draftJSToMarkdownRawParser } from 'module/Widgets/Markdown/Markdown.util';

import { useAuth, useUserCatalog } from 'provider';

import { orgIdOrDefault } from 'services/organizationService';

import { ContentPermission, FunctionalPermission } from 'types/auth';
import * as CommonTypes from 'types/common';
import { Guid, SavedFileMetadata } from 'types/common';

import { deleteAttachment } from '../Intel.api';
import { decomposeIntel } from '../Intel.helper';
import { ArtifactStyle } from '../Intel.style';
import { DuplicateReference, Intel, IntelRouterState, LinkSummary } from '../Intel.type';
import IntelForm from '../IntelCore/IntelForm';
import useSummarize from '../IntelCore/useSummarize';
import useIntel from '../useIntel';
import IntelEditHeader from './IntelEditHeader';

export type EditIntelForm = {
  organization_id: string;
  owner: string;
  source: string;
  image: string;
  indicators: Omit<IOC, 'hasTemplate'>[];
  large_image: string;
  title: string;
  original_author: string;
  description: string;
  attack_names: string[];
  actor_names: string[];
  software_names: string[];
  vulnerability_names: string[];
  reference: string[];
  url: string;
  quick_add_attachment: File[];
  attachment: File[];
  existingFiles?: SavedFileMetadata[];
  visibility: CommonTypes.Visibility;
  guid?: Guid;
};

const IntelEdit = ({ history }: RouteComponentProps): ReactElement => {
  useNoPermitCreateRedirect(CommonTypes.ArtifactType.Intel);
  const { user, defaultOrgId, permission: organizations } = useAuth();
  const { users } = useUserCatalog();
  const orgMapping = useOrganizationOptions(FunctionalPermission.CreateIntel);
  const { id: guid } = useParams<{ id: string }>();
  const { item, references, permission } = useLocationState<IntelRouterState>() || {};
  const { intel, error, isPending } = useIntel(guid, item, references, permission);
  const { unfurl, errorProps } = useSummarize();
  const [serviceError, setServiceError] = React.useState<CommonTypes.FieldError>(null);
  const [isActing, setIsActing] = React.useState(false);
  const [action, setAction] = React.useState(null);
  const [iocPayload, setIocPayload] = React.useState<IOC[]>([]);
  const [duplicateReferences, setDuplicateReferences] = React.useState<DuplicateReference[]>([]);
  useTitle(guid ? 'Edit Intelligence | SnapAttack' : 'Create Intelligence | SnapAttack');
  const { annotateIOCsWithWarnings } = useAnnotateIOCs();
  const handleUpdate = (
    { submitForm, setValues, values }: FormikProps<EditIntelForm>,
    visibility: CommonTypes.Visibility
  ) => {
    setValues({ ...values, visibility: visibility });
    return submitForm();
  };

  const redirectToViewPage = (intel: Intel) => {
    history.replace(`${Path.Intelligence}/${intel?.guid}`, decomposeIntel(intel));
  };

  const handleSubmit = async (values: EditIntelForm) => {
    setAction('Saving...');
    setIsActing(true);
    setServiceError(null);
    const payload = { ...values, attachment: [...values.quick_add_attachment, ...values.attachment] };
    delete payload.quick_add_attachment;
    try {
      // DELETE files that were removed from the existing files
      const filesToDelete = intel.files?.filter(
        originalFile => !values.existingFiles?.some(keptFile => originalFile.guid === keptFile.guid)
      );
      if (filesToDelete?.length) {
        Promise.all(filesToDelete.map(file => deleteAttachment(file.guid)));
      }

      let updatedIntel;
      if (guid) {
        const updatePayload: EditIntelForm = { ...payload };
        if (payload.indicators?.length) {
          const extractResponse = await extractIndicatorsFromText(payload.indicators.join(' '));
          updatePayload.indicators = extractResponse.indicators;
        }
        updatedIntel = await updateIntel(guid, updatePayload);
      } else {
        updatedIntel = await createNewIntel(payload);
      }

      if (guid) {
        Engage.track(Fingerprint.of(Path.Intelligence).withContent(ContentPermission.Edit).withData(updatedIntel));
      } else {
        Engage.trackPersonIncrement(FunctionalPermission.CreateIntel, 1);
      }

      redirectToViewPage(updatedIntel);
    } catch (err) {
      // the backend may send a helpful error message in the response payload as { detail: string; }
      const validationError = asValidationError(err)?.detail;
      let fieldError;
      if (validationError) fieldError = { message: validationError };

      setIsActing(false);
      setServiceError(fieldError);
      Engage.track(
        Fingerprint.of(Path.Intelligence).withContent(ContentPermission.Edit).withData({ error: fieldError }).asError()
      );
    }
  };

  React.useEffect(() => {
    if (intel.guid && intel.name) {
      Engage.track(
        Fingerprint.load(Path.IntelligenceEdit).withData({
          guid: intel.guid,
          name: intel.name,
          artifact_organization_id: intel.organization_id,
          artifact_organization_name: organizations?.find(o => o.id === intel.organization_id)?.name
        })
      );
      Engage.trackPersonIncrement(`view ${Path.IntelligenceEdit}`, 1);
    } else if (!guid) {
      Engage.track(Fingerprint.load(Path.IntelligenceCreate));
      Engage.trackPersonIncrement(`view ${Path.IntelligenceCreate}`, 1);
    }
  }, [guid, intel.guid, intel.name, intel.organization_id, organizations]);

  if (error.intel)
    return <NotFound artifact={CommonTypes.ArtifactType.Intel} error={{ response: error.intel, tag: guid }} />;

  return (
    <Formik
      {...standardFormikBaseProps}
      initialValues={{
        organization_id: orgIdOrDefault(intel.organization_id, defaultOrgId).toString(),
        owner: users.find(user => user.id === intel.created_by_id)?.name || user.name,
        source: intel.url || '',
        image: intel.small_image || '',
        large_image: intel.large_image || '',
        title: intel.name || '',
        original_author: intel.original_author || '',
        description: draftJSToMarkdownRawParser(intel.description) || '',
        attack_names: intel.attack_names || [],
        actor_names: intel.actor_names || [],
        software_names: intel.software_names || [],
        vulnerability_names: intel.vulnerability_names || [],
        reference: (intel.references && intel.references) || [],
        quick_add_attachment: [],
        existingFiles: intel.files || [],
        attachment: [],
        visibility: intel.visibility,
        indicators: (intel?.indicators || []).map(ioc => ioc.name)
      }}
      onSubmit={handleSubmit}
      validationSchema={object().shape({
        title: string().required('Title is required'),
        description: string().required('Description is required')
      })}
    >
      {(formikProps: FormikProps<EditIntelForm>): React.ReactElement => {
        const handleUnfurl = async (uploadedItem: Parameters<typeof unfurl>[0]) => {
          setAction('Extracting and Summarizing Intelligence...');
          setIsActing(true);

          try {
            const summary: LinkSummary = await unfurl(uploadedItem);
            if (summary?.duplicates) {
              setDuplicateReferences(summary.duplicates);
            }

            const indicators = await annotateIOCsWithWarnings(
              summary.indicators
                .map(i => ({ ...i, organization_id: defaultOrgId }))
                .filter((ioc: IOC) => !iocPayload.map(currentIoc => currentIoc.value).includes(ioc.value)),
              false
            );

            if (summary?.indicators) setIocPayload(iocPayload => [...iocPayload, ...indicators]);

            const formValues: EditIntelForm = {
              ...formikProps.values,
              title: summary.title || '',
              image: summary.image || '',
              indicators: indicators || [],
              source: summary.source || '',
              description: summary.body || '',
              original_author: summary.author || '',
              attack_names: [
                ...new Set(
                  summary.tags
                    ?.filter(t => t.discriminator === Discriminator.Attack)
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map(t => t.name)
                )
              ],
              actor_names: [
                ...new Set(
                  summary.tags
                    ?.filter(t => t.discriminator === Discriminator.Actor)
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map(t => t.name)
                )
              ],
              software_names: [
                ...new Set(
                  summary.tags
                    ?.filter(t => t.discriminator === Discriminator.Software)
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map(t => t.name)
                )
              ],
              vulnerability_names: [
                ...new Set(
                  summary.tags
                    ?.filter(t => t.discriminator === Discriminator.Vulnerability)
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map(t => t.name)
                )
              ]
            };
            // only apply reference if the upload if a url and not a File
            if (typeof uploadedItem === 'string') {
              formValues['reference'] = [...formValues.reference, uploadedItem];
              formValues['url'] = summary.source;
            }
            formikProps.setValues(formValues);
          } catch (e) {
            // do nothing
          }

          setIsActing(false);
        };
        return (
          <ArtifactStyle
            isPending={isPending}
            meta={
              <IntelEditHeader
                intel={
                  guid
                    ? intel
                    : ({
                        created_by_id: user.id,
                        organization_id: parseInt(formikProps.values.organization_id || '0')
                      } as Intel)
                }
                onPublish={() => handleUpdate(formikProps, CommonTypes.Visibility.Published)}
                onDraft={() => handleUpdate(formikProps, CommonTypes.Visibility.Draft)}
                onReset={() => formikProps.resetForm()}
                dirty={formikProps.dirty}
                onUnfurl={handleUnfurl}
              />
            }
            type={CommonTypes.ArtifactType.Intel}
            label={guid ? 'Edit Intelligence' : 'Create Intelligence'}
          >
            <BackdropLoader title={action} open={isActing} fixed />
            <AlertError error={serviceError} />
            <ApiError {...errorProps} className='Intel-error' />
            {duplicateReferences.length > 0 && (
              <Alert severity='info'>
                <AlertTitle>
                  This intelligence report already exists in the platform. You may continue to add it, but consider
                  using an existing one.
                </AlertTitle>
                <ul>
                  {duplicateReferences.map((reference, idx) => (
                    <li key={idx}>
                      <ExternalLink icon primary href={Path.Intelligence + '/' + reference.guid}>
                        {reference.name}
                      </ExternalLink>
                    </li>
                  ))}
                </ul>
              </Alert>
            )}
            <IntelForm
              orgOptions={orgMapping}
              iocPayload={iocPayload}
              setIocPayload={setIocPayload}
              setIsActing={setIsActing}
            />
          </ArtifactStyle>
        );
      }}
    </Formik>
  );
};

export default IntelEdit;
