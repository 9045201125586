import * as Sentry from '@sentry/react';

import config from 'config/config';

import { Monitoring } from 'types/auth';

import history from '../../history';

export default async function withSentry(app: React.FC, settings: Monitoring) {
  if (process.env.NODE_ENV !== 'production' || !settings?.errors?.enabled) return app;

  Sentry.init({
    dsn: settings.errors.datasource,
    integrations: [Sentry.reactRouterV5BrowserTracingIntegration({ history })],
    sampleRate: settings.errors.error_sample_rate,
    tracesSampleRate: settings.errors.profile_sample_rate,
    debug: settings.errors.debug,
    environment: settings.errors.environment,
    release: settings.errors.release
  });

  Sentry.setContext('config', config);

  return Sentry.withProfiler(app);
}
