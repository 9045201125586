import React from 'react';

import {
  Legend,
  PolarAngleAxis,
  PolarGrid,
  Radar,
  RadarChart,
  Tooltip as ReChartsToolTip,
  ResponsiveContainer
} from 'recharts';

import Alert from 'snap-ui/Alert';
import Checkbox from 'snap-ui/Checkbox';
import Tooltip from 'snap-ui/Tooltip';
import { styled, useTheme } from 'snap-ui/util';

import { EMPTY_ATTACK_COVERAGE } from 'module/Dashboard/SocDashboard/SocDashboard.const';
import { DefaultColorObj, RadarData } from 'module/Dashboard/SocDashboard/SocDashboard.type';

import { TooltipDefaults } from './components/TooltipDefaults';

export const MIN_PERCENTAGE = 0.9;

type Props = {
  data?: RadarData[];
  colorKey: Record<string, number>;
};

const StyledCheckBox = styled(Checkbox, { shouldForwardProp: p => p !== 'colors' })<{
  colors: DefaultColorObj[];
  index: number;
}>`
  color: ${p => p.colors[p.index].main} !important;
`;

const CustomLegendContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 50px;
  .chip {
    margin-right: ${p => p.theme.spacing(2)};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

const SpecifiedDomainRadarChart = ({ data = [], colorKey }: Props) => {
  const [disabled, setDisabled] = React.useState([]);
  const { palette } = useTheme();
  const defaultColors = [palette.red, palette.blue, palette.orange, palette.warning, palette.success];
  const getColorIndex = (name: string, index: number) => (colorKey[name] || index) % defaultColors.length;

  const dataKeys: string[] =
    data.length === 0 ? Object.keys(EMPTY_ATTACK_COVERAGE[0].values) : Object.keys(data[0].values);
  const handleClick = dataKey => {
    if (disabled.includes(dataKey)) {
      setDisabled(disabled => {
        return disabled.filter(obj => obj !== dataKey);
      });
    } else {
      setDisabled(disabled => {
        return disabled.concat(dataKey);
      });
    }
  };

  const CustomLegend = ({ dataKeys = [] }: { dataKeys: string[] }) => {
    if (data.length === 0)
      return (
        <CustomLegendContainer>
          <Alert severity='info' width='200'>
            Deploy detections to improve ATT&CK coverage
          </Alert>
        </CustomLegendContainer>
      );
    const disableButton = dataKeys.length - disabled.length === 1;

    return (
      <CustomLegendContainer>
        {dataKeys.map((key, index) => {
          const active = disabled.includes(key);
          const colorIndex = getColorIndex(key, index);

          return (
            <div key={index} className='chip'>
              <StyledCheckBox
                value={key}
                checked={!active}
                onChange={() => handleClick(key)}
                colors={defaultColors}
                index={colorIndex}
                disabled={disableButton && !active}
              />
              <Tooltip arrow title={key} wrap placement='top'>
                <>{key}</>
              </Tooltip>
            </div>
          );
        })}
      </CustomLegendContainer>
    );
  };

  const renderRadars = () => {
    return dataKeys
      .filter(name => {
        return !disabled.includes(name);
      })
      .map((name, index) => {
        const colorIndex = getColorIndex(name, index);

        return (
          <Radar
            name={name}
            dataKey={`values.${name}`}
            stroke={defaultColors[colorIndex].main}
            fill={defaultColors[colorIndex].main}
            fillOpacity={0.6}
            key={`${index}-${name}`}
          />
        );
      });
  };

  return (
    <ResponsiveContainer width='100%' height={550}>
      <RadarChart cx='50%' cy='50%' outerRadius='80%' data={data.length === 0 ? EMPTY_ATTACK_COVERAGE : data}>
        <PolarGrid />
        <PolarAngleAxis tick={{ fill: 'white' }} width='45' dataKey={item => item?.name || ''} dy={5} />
        {renderRadars()}
        <Legend content={<CustomLegend dataKeys={dataKeys} />} />
        <ReChartsToolTip
          {...TooltipDefaults}
          formatter={value => {
            return value === MIN_PERCENTAGE ? 0 : value;
          }}
        />
      </RadarChart>
    </ResponsiveContainer>
  );
};

export default SpecifiedDomainRadarChart;
