import React from 'react';

import { Option } from 'snap-ui/Autocomplete';

import { CancelToken } from 'apis';

import useDebounce from 'hooks/useDebounce';

import { useManagedOrganizations } from 'provider';

import { Status, useAsync } from 'storage';

import { ArtifactType } from 'types/common';

import { getDeprecatedArtifactType } from 'utilities/ArtifactUtils';
import { NOOP } from 'utilities/FunctionUtils';

import { searchArtifactByType } from './Search.api';
import { AutocompleteResponse, SearchCompatibleArtifactType } from './Search.type';

type AutocompleteCompatibleArtifactTypes =
  | ArtifactType.Intel
  | ArtifactType.Session
  | ArtifactType.Analytic
  | ArtifactType.AttackScript;

function useAutocomplete(type: AutocompleteCompatibleArtifactTypes) {
  const deprecatedType: SearchCompatibleArtifactType = getDeprecatedArtifactType(type) as SearchCompatibleArtifactType;
  const { data, setData, status, task } = useAsync<AutocompleteResponse>([]);
  const cancelTokenSourceRef = React.useRef(CancelToken.source());

  const search = React.useCallback(
    (partial: string) => {
      cancelTokenSourceRef.current.cancel();
      const source = CancelToken.source();
      cancelTokenSourceRef.current = source;
      task(searchArtifactByType(partial, [deprecatedType], { cancelToken: source.token })).then(d => setData(d), NOOP);
    },
    [deprecatedType, setData, task]
  );

  return {
    data,
    search,
    status
  };
}

type ArtifactOptionInterface = {
  options: Option[];
  optionsWithOrganizations: Option[];
  search(partial: string): void;
  searching: boolean;
  searchTerm: string;
  setSearchTerm(value: string): void;
};

export function useArtifactOptions(type: AutocompleteCompatibleArtifactTypes): ArtifactOptionInterface {
  const { data, search, status } = useAutocomplete(type);
  const [searchTerm, setSearchTerm] = React.useState('');
  const [_effectiveSearchTerm, setEffectiveSearchTerm] = React.useState(searchTerm);
  const effectiveSearchTerm = useDebounce(_effectiveSearchTerm);
  const { organizations } = useManagedOrganizations();

  React.useEffect(() => {
    if (searchTerm.length > 2) setEffectiveSearchTerm(searchTerm);
    else setEffectiveSearchTerm('');
  }, [searchTerm, setEffectiveSearchTerm]);

  React.useEffect(() => {
    if (search) search(effectiveSearchTerm);
  }, [search, effectiveSearchTerm]);

  const options = React.useMemo(
    () =>
      data.map<Option>(value => ({
        label: value.name,
        content: value.name,
        value: value.guid
      })),
    [data]
  );

  const optionsWithOrganizations = React.useMemo(() => {
    return data.map<Option>(value => {
      const orgName = organizations.find(org => org.id === value.organization_id)?.name;
      return {
        label: value.name,
        content: orgName ? `${value.name} | ${orgName}` : value.name,
        value: value.guid
      };
    });
  }, [data, organizations]);

  return {
    options,
    optionsWithOrganizations,
    search,
    searching: status === Status.pending,
    searchTerm,
    setSearchTerm
  };
}

export default useAutocomplete;
