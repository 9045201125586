import React from 'react';

import { CancelToken } from 'apis';

import { Discriminator, SigmaTag, getSigmaTagsByAnyDiscriminators } from 'module/Tag';

import { Status, useAsync } from 'storage';

const ALL_TYPES = [Discriminator.Actor, Discriminator.Attack, Discriminator.Software, Discriminator.Vulnerability];
const LIMIT = 25;

const DEFAULT_OPTIONS: SearchAllTagsOptions = {
  types: ALL_TYPES,
  onlyNames: false,
  limit: LIMIT
};

type SearchAllTagsOptions = {
  types?: Discriminator[];
  onlyNames?: boolean;
  limit?: number;
};
export function useSearchAllTags(options?: SearchAllTagsOptions) {
  const effectiveOptions = { ...DEFAULT_OPTIONS, ...options };
  const { types, onlyNames, limit } = effectiveOptions;
  const { data, run, status, reset } = useAsync<SigmaTag[]>([]);
  const cancelTokenSourceRef = React.useRef(CancelToken.source());

  const search = React.useCallback(
    (partial: string) => {
      cancelTokenSourceRef.current.cancel();
      const source = CancelToken.source();
      cancelTokenSourceRef.current = source;
      run(
        getSigmaTagsByAnyDiscriminators(limit, { partial, types, only_names: onlyNames }, { cancelToken: source.token })
      );
    },
    [limit, onlyNames, run, types]
  );

  return {
    data,
    searching: status === Status.pending,
    search,
    reset
  };
}

export function useTagCatalog() {
  const { data, search, ...rest } = useSearchAllTags({
    limit: 10_000
  });

  React.useEffect(() => search(''), [search]);

  return {
    data,
    ...rest
  };
}
