import React from 'react';

import { IconDefinition } from '@fortawesome/fontawesome-common-types';

import { HeatMap } from 'module/Matrix/Matrix.type';

import { Organization, User } from 'types/auth';
import { StrictReactNode } from 'types/core';

export enum StashListType {
  stash = 'stash',
  option = 'option'
}

export enum MatrixDisplayOption {
  MitreID = 'mitre-id',
  Count = 'count',
  Empty = 'empty',
  Subtechnique = 'sub-techniques',
  PreAttack = 'pre-attack'
}

export enum MatrixPaletteOption {
  Grayscale = 'gray',
  Snap = 'snap',
  Traffic = 'traffic',
  Red = 'red',
  Blue = 'blue',
  ThreatProfile = 'threatProfile'
}

export type MatrixDisplay = {
  display: MatrixDisplayOption[];
  palette: MatrixPaletteOption;
  heatMap: HeatMap;
  bounds: number;
};

export type Glossary = {
  [index: string]: HTMLElement;
};

export enum MenuOrdinal {
  Research,
  Detect,
  Hunt,
  Validate,
  Report,
  Sandbox,
  Help,
  Search,
  Profile,
  Composite,
  Burger
}

export type Stashed = {
  id: string;
  closedContent?: StrictReactNode;
  openedContent?: StrictReactNode;
};

export type Stash = {
  icon: IconDefinition;
  items: Stashed[];
  options?: Stashed[];
};

export type SettingMenuItem = {
  subscriber?: boolean;
  path: string;
  enablePathIdVariable?: boolean;
  name: string;
  icon: IconDefinition;
  component: React.FunctionComponent<any>;
  getAllowed?: (user: User, organizations: Organization[]) => boolean;
};
