import React from 'react';

import { faFileLines, faShareNodes, faSitemap } from '@fortawesome/pro-solid-svg-icons';
import { Link } from 'react-router-dom';

import Icon from 'snap-ui/Icon';
import Tooltip from 'snap-ui/Tooltip';
import { styled } from 'snap-ui/util';

import Path from 'constants/paths';

import useSplunkLink from 'hooks/useSplunkLink';

import Can from 'module/Can';
import { SessionIconButton } from 'module/Session/Session.helper';
import { Host, RedMarkerCreationPayload, Session } from 'module/Session/Session.type';
import CopyButton from 'module/Widgets/CopyButton';
import { RedMarkerButton, RedMarkerDialog, RedMarkerInterface } from 'module/Widgets/RedMarkerModal';

import { checkContentPermission } from 'services/authService';

import { ContentPermission, FunctionalPermission } from 'types/auth';

import { formatQueryString } from 'utilities/SearchParam';
import { splunkDashboardUrl } from 'utilities/SplunkUtils';

interface Props {
  addMarker(marker: RedMarkerCreationPayload): Promise<void>;
  containerRef: any;
  currentTime: number;
  url: string;
  activeHost: Host;
  session: Session;
  hasHostGraph: boolean;
}

const Container = styled('div')`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 40px;
  background-color: ${p => p.theme.palette.common.black};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
  gap: ${p => p.theme.spacing(3)};

  .RedMarkerPopup {
    > button {
      font-size: 24px;
    }
  }

  .marker-container {
    position: relative;
  }
  .Tooltip-Wrapper {
    margin-bottom: 15px;
  }

  .button {
    padding: 0;
    font-size: 150%;

    i {
      margin: 0;
    }

    &:hover {
      background-color: transparent !important;
    }
  }
`;

export default function Toolbar(props: Props) {
  const { currentTime, activeHost } = props;
  const [openTime, setOpenTime] = React.useState(0);

  React.useEffect(() => {
    setOpenTime(currentTime);
  }, [currentTime]);

  const addMarker = async function (data: RedMarkerCreationPayload) {
    await props.addMarker(data);
  };

  if (!addMarker) {
    return null;
  }

  /**
   * Returns a simple object with properties minTime and maxTime.
   * When currentTime is not set or 0, then return start and end times.
   * Otherwise, return a range offset in either direction from current
   * time by 15 seconds
   */
  const getTimeRange = () => {
    const startTime = new Date(props.activeHost.start).getTime();
    const endTime = new Date(props.activeHost.end).getTime();

    if (props.currentTime <= 0) {
      return {
        minTime: startTime,
        maxTime: endTime
      };
    }

    const currentTime = props.currentTime < 1 ? 0 : props.currentTime * 1000;
    const min = Math.max(startTime + currentTime - 15_000, startTime);
    const max = Math.min(startTime + currentTime + 15_000, endTime);

    return {
      minTime: min,
      maxTime: max
    };
  };

  const canEdit = checkContentPermission(props.session, ContentPermission.Edit);

  return (
    <Container className='VideoToolbar'>
      {canEdit && (
        <RedMarkerInterface>
          <Tooltip placement='left' title='Label attack' wrap arrow>
            <SessionIconButton size='medium'>
              <RedMarkerButton newMarker offset={openTime} startTime={activeHost.start} />
            </SessionIconButton>
          </Tooltip>
          <RedMarkerDialog onAdd={addMarker} />
        </RedMarkerInterface>
      )}
      <CopyButton
        value={props.url}
        placement='left'
        tooltip='Share session link'
        copyTooltip='Copied to clipboard!'
        messageTime={2000}
        arrow={true}
      >
        <span className='Tooltip-wrapper'>
          <SessionIconButton size='medium' color='inherit'>
            <Icon icon={faShareNodes} />
          </SessionIconButton>
        </span>
      </CopyButton>
      {props.hasHostGraph ? (
        <Tooltip
          placement='left'
          title={props.activeHost.is_attacker ? 'No process graph for attacker machine' : 'View on process graph'}
          wrap
          arrow
        >
          {props.activeHost.is_attacker ? (
            <SessionIconButton size='medium'>
              <Icon icon={faSitemap} rotation={270} color='inherit' />
            </SessionIconButton>
          ) : (
            <Link
              target='_blank'
              to={{
                pathname: Path.ProcessGraph,
                search: formatQueryString({
                  threatId: props.session.guid,
                  hostId: props.activeHost.guid,
                  hostname: props.activeHost.machine.name,
                  nodeId: props.activeHost.machine.name,
                  ...getTimeRange()
                })
              }}
            >
              <SessionIconButton size='medium'>
                <Icon icon={faSitemap} rotation={270} color='inherit' />
              </SessionIconButton>
            </Link>
          )}
        </Tooltip>
      ) : null}
      <Can I={FunctionalPermission.SplunkUIAccess}>
        <Can.Grant>
          <SplunkLink activeHost={props.activeHost} currentTime={props.currentTime} session={props.session} />
        </Can.Grant>
        <Can.Block>
          <Tooltip title='Event logs are only accessible to contributors' placement='left' wrap arrow>
            <SessionIconButton size='medium' disabled>
              <Icon icon={faFileLines} color='inherit' />
            </SessionIconButton>
          </Tooltip>
        </Can.Block>
      </Can>
    </Container>
  );
}

function SplunkLink({
  activeHost,
  currentTime,
  session
}: {
  activeHost: Host;
  currentTime: number;
  session: Session;
}): JSX.Element {
  const { hostLogDashboard } = useSplunkLink();

  return (
    <Tooltip placement='left' title='View nearby event logs' wrap arrow>
      <a
        href={splunkDashboardUrl({
          dashboard: hostLogDashboard,
          neartime: new Date(`${activeHost.start}+00:00`).getTime() + currentTime * 1000,
          session
        })}
        target='_blank'
        rel='noopener noreferrer'
      >
        <SessionIconButton size='medium'>
          <Icon icon={faFileLines} color='inherit' />
        </SessionIconButton>
      </a>
    </Tooltip>
  );
}
