import { CancelTokenType, snapattack } from 'apis/snapattack';

import {
  Detection,
  DetectionStatsResources,
  DetectionSummary,
  DetectionSummaryCatalog,
  LogPreviewResult
} from 'module/Detection';
import { JobGroupResult } from 'module/Job';

import { JobHit } from 'types/bas';
import { Guid } from 'types/common';
import { LogRecord } from 'types/ide';
import { MarkerEvent } from 'types/marker';

const prefix = '/detection';

export function getStats(resource: DetectionStatsResources, guid: Guid): Promise<Detection> {
  return snapattack.get(`${prefix}/stats/${resource}/${guid}/`).then(r => r.data);
}

/**
 * Global Summary very expensive - dashboard only
 */
export function getStatSummary(): Promise<DetectionSummary> {
  return snapattack.get(`${prefix}/stats/cached/summary/`).then(r => r.data);
}

export function getEvent(eventIdentifier: string): Promise<MarkerEvent> {
  return snapattack.post(`${prefix}/events/${eventIdentifier}/`).then(r => r.data);
}

export function getStatCount(resource: DetectionStatsResources, group: Guid[]): Promise<DetectionSummaryCatalog> {
  return snapattack.post(`${prefix}/stats/cached/summary/${resource}s/`, group).then(r => r.data);
}

export function getAnalyticDetectionCount(analyticGuid: Guid): Promise<DetectionSummary> {
  return snapattack.get(`${prefix}/stats/signature/${analyticGuid}/summary/`).then(r => r.data);
}

export function getRawLog(rowId: string): Promise<LogRecord> {
  return snapattack.get(`${prefix}/events/raw/${rowId}/`).then(r => r.data);
}

export function getJobHits(jobGuid: Guid): Promise<JobHit[]> {
  return snapattack.get(`${prefix}/events/client/analytic_hits/bas/${jobGuid}/`).then(r => r.data);
}

export function getJobGroupResult(jobGuid: Guid): Promise<JobGroupResult[]> {
  return snapattack
    .post(`${prefix}/events/client/analytic_hits/job/groups/export/${jobGuid}/`, {
      csv: false
    })
    .then(r => r.data);
}
export function getPreviewLogs(guid: Guid, config?: { cancelToken?: CancelTokenType }): Promise<LogPreviewResult> {
  return snapattack.get(`${prefix}/marking/signature/${guid}/preview/`, config).then(r => r.data);
}
