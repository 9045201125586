import { SearchParamChange } from './Filter.type';

type Value = {
  value: string[];
  onChange: SearchParamChange;
};

type Option = {
  option: any[];
};

/**
  return true if passing nextProps to render would return
  the same result as passing prevProps to render,
  otherwise return false
*/
export function valuesAndOptionsAreEqual(prevProps: Value & Option, nextProps: Value & Option): boolean {
  const pOption = JSON.stringify(prevProps.option);
  const nOption = JSON.stringify(nextProps.option);
  const values = valuesAreEqual(prevProps, nextProps);
  return pOption === nOption && values;
}

export function valuesAreEqual(prevProps: Value, nextProps: Value) {
  const pValue = JSON.stringify(prevProps.value);
  const nValue = JSON.stringify(nextProps.value);
  return pValue === nValue && prevProps.onChange === nextProps.onChange;
}

export function addOrRemoveValue<T>(add: boolean, values: T[], newValue: T): T[] {
  return add ? [...values, newValue] : values.filter(s => s !== newValue);
}
