import React from 'react';

import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis
} from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

import PrimaryCircularProgress from 'snap-ui/CircularProgress';
import Grid from 'snap-ui/Grid';
import Typography from 'snap-ui/Typography';
import { styled, useTheme } from 'snap-ui/util';

import { JobGroupResult } from 'module/Job';

import { ArtifactScore } from 'types/common';

import DashboardGrid from '../core/DashboardGrid';
import PanelPaper from '../core/PanelPaper';
import { countConfidenceOccurrences, uniqueBulkConfidence } from './BulkConfidence.helper';
import { CountChangeDifference } from './BulkConfidence.type';

type ConfidenceActivityProps = {
  loading: boolean;
  bulkConfidence: JobGroupResult[];
};
const Container = styled(DashboardGrid)`
  .dashboard-paper {
    padding: 0;
    justify-content: center;
  }
  .activity-title {
    text-align: center;
  }

  .recharts-tooltip-cursor {
    display: none;
  }
  .recharts-responsive-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .legend-text {
    color: ${p => p.theme.palette.common.white};
  }

  .bar-label {
    font-weight: bolder;
  }
  .custom-tooltip {
    background-color: ${p => p.theme.palette.background.default};
    padding: ${p => p.theme.spacing(3)};
  }

  .snap-tooltip {
    color: white;
    fill: white;
    stroke: white;
    border: 1px solid orange;
  }
`;

function ConfidenceActivity({ loading, bulkConfidence: jobResults = [] }: ConfidenceActivityProps) {
  const { palette } = useTheme();
  const bulkConfidence = uniqueBulkConfidence(jobResults) as JobGroupResult[];
  const distributionName = Object.values(ArtifactScore);
  const allOldConfidence = bulkConfidence.map(detection => detection.old_rank) || [];
  const allNewConfidence = bulkConfidence.map(detection => detection.newRank) || [];
  const distributionBarData = distributionName.map(name => ({
    name: name,
    oldConfidence: countConfidenceOccurrences(allOldConfidence, name),
    newConfidence: countConfidenceOccurrences(allNewConfidence, name)
  }));
  const differenceColor = [
    'hsla(0, 100%, 50%, 1)',
    'hsla(0, 100%, 55%, 1)',
    'hsla(0, 100%, 50%, 1)',
    'hsla(0, 100%, 65%, 1)',
    'hsla(0, 100%, 75%, 1)',
    'hsla(0, 0%, 75%, 1)',
    'hsl(102, 73%, 74%, 1)',
    'hsl(102, 73%, 64%, 1)',
    'hsl(102, 73%, 54%, 1)',
    'hsl(102, 73%, 44%, 1)',
    'hsl(102, 73%, 40%, 1)'
  ];
  const countChangeConfidenceName = CountChangeDifference;
  const countChangeConfidenceData = countChangeConfidenceName.map((name, index) => ({
    name,
    count: countConfidenceOccurrences(
      bulkConfidence.map(detection => detection.diff),
      name
    ),
    fill: differenceColor[index]
  }));
  const colors = [
    palette.error.main,
    palette.orange.main,
    palette.warning.main,
    palette.success.main,
    palette.info.main,
    palette.grey[500]
  ];
  const mostConfidenceChangeData = distributionName.map((name, index) => ({
    name: name,
    count: Math.abs(
      countConfidenceOccurrences(
        bulkConfidence.map(detection => detection.newRank),
        name
      ) -
        countConfidenceOccurrences(
          bulkConfidence.map(detection => detection.old_rank),
          name
        )
    ),
    fill: colors[index]
  }));

  const renderColorfulLegendText = (value: string) => {
    return <span className='legend-text'>{value}</span>;
  };
  const renderCustomBarLabel = ({ x, y, width, value }) => {
    return (
      <text className='bar-label' x={x + width / 2} y={y} fill={palette.common.black} textAnchor='middle' dy={20}>
        {value > 0 ? value : null}
      </text>
    );
  };
  const CustomTooltip = ({ active, payload, label, type }: TooltipProps<ValueType, NameType> & { type?: string }) => {
    if (active && payload && payload.length) {
      return (
        <div className='custom-tooltip'>
          <p className='label'>{`${type ? 'Old' : ''} ${label} : ${payload[0].value}`}</p>
          {payload[1] && <p className='label'>{`${type ? 'New' : ''} ${label} : ${payload[1].value}`}</p>}
        </div>
      );
    }

    return null;
  };

  return (
    <Container>
      <Grid container spacing={5}>
        <Grid item xs={12} md={6} lg={4}>
          <PanelPaper className='dashboard-paper'>
            <Typography className='activity-title' variant='h4'>
              Confidence Level Distribution
            </Typography>
            <ResponsiveContainer width='100%' height={350}>
              {loading ? (
                <PrimaryCircularProgress size={80} />
              ) : (
                <BarChart margin={{ top: 5, right: 20, left: -20, bottom: 10 }} data={distributionBarData}>
                  <CartesianGrid strokeDasharray='3 3' stroke={palette.common.white} />
                  <XAxis interval={0} stroke={palette.common.white} dataKey='name' />
                  <YAxis stroke={palette.common.white} />
                  <Tooltip content={p => <CustomTooltip type='distribution' {...p} />} />
                  <Legend formatter={renderColorfulLegendText} />
                  <Bar
                    name='Old Confidence'
                    dataKey='oldConfidence'
                    fill={'hsla(169, 58%, 58%)'}
                    label={renderCustomBarLabel}
                  />
                  <Bar
                    name='New Confidence'
                    dataKey='newConfidence'
                    fill={palette.primary.main}
                    label={renderCustomBarLabel}
                  />
                </BarChart>
              )}
            </ResponsiveContainer>
          </PanelPaper>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <PanelPaper className='dashboard-paper'>
            <Typography className='activity-title' variant='h4'>
              Number of Confidence Levels Shifted
            </Typography>
            <ResponsiveContainer width={'100%'} height={350}>
              {loading ? (
                <PrimaryCircularProgress size={80} />
              ) : (
                <BarChart margin={{ top: 5, right: 20, left: -20, bottom: 10 }} data={countChangeConfidenceData}>
                  <CartesianGrid strokeDasharray='3 3' />
                  <XAxis stroke={palette.common.white} dataKey='name' />
                  <YAxis stroke={palette.common.white} />
                  <Tooltip content={p => <CustomTooltip {...p} />} />
                  <Bar name='Count Change' dataKey='count' fill={palette.common.white} />
                </BarChart>
              )}
            </ResponsiveContainer>
          </PanelPaper>
        </Grid>
        <Grid item xs={12} md={12} lg={4}>
          <PanelPaper className='dashboard-paper'>
            <Typography className='activity-title' variant='h4'>
              Most Changed Confidence Levels
            </Typography>
            <ResponsiveContainer width={'100%'} height={350}>
              {loading ? (
                <PrimaryCircularProgress size={80} />
              ) : (
                <BarChart
                  margin={{ top: 5, right: 20, left: -20, bottom: 10 }}
                  data={mostConfidenceChangeData}
                  className='level-intensity'
                >
                  <CartesianGrid strokeDasharray='3 3' />
                  <XAxis interval={0} stroke={palette.common.white} dataKey='name' />
                  <YAxis stroke={palette.common.white} />
                  <Tooltip content={p => <CustomTooltip {...p} />} />
                  <Bar name='Most Change' dataKey='count' label={renderCustomBarLabel} fill={palette.common.white} />
                </BarChart>
              )}
            </ResponsiveContainer>
          </PanelPaper>
        </Grid>
      </Grid>
    </Container>
  );
}

export default ConfidenceActivity;
