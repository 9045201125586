import { theme } from 'snap-ui/theme';

import { MatrixPaletteOption } from 'module/Scaffold/Scaffold.type';
import { WeightMap } from 'module/Widgets/StateLabel/ThreatProfilePriority';

import { HeatMap, Palette, Tier } from './Matrix.type';

export const NUMBUCKETS = 5;

export const catalog: { [index: string]: Palette } = {
  [MatrixPaletteOption.Grayscale]: [
    { background: '#222222', contrast: '#1a1a1a', text: theme.palette.text.primary },
    { background: '#343434', contrast: '#2b2b2b', text: '#fff' },
    { background: '#454545', contrast: '#3c3c3c', text: '#fff' },
    { background: '#565656', contrast: '#4d4d4d', text: '#fff' },
    { background: '#787878', contrast: '#676767', text: '#000' },
    { background: '#9a9a9a', contrast: '#898989', text: '#000' }
  ],

  [MatrixPaletteOption.Traffic]: [
    { background: '#b35a5a', contrast: '#994d4d', text: '#000' }, // blood
    { background: '#ff8181', contrast: '#b35a5a', text: '#000' }, // red
    { background: '#FFB281', contrast: '#b37d5a', text: '#000' }, // orange ish
    { background: '#ffd781', contrast: '#b3975a', text: '#000' }, // yellow / gold
    { background: '#C9E887', contrast: '#8da25f', text: '#000' }, // lime
    { background: '#81ff8e', contrast: '#5ab363', text: '#000' } // green
  ],
  [MatrixPaletteOption.ThreatProfile]: Object.values(WeightMap).map(chipProps => {
    return {
      background: theme.palette[chipProps.color].main as string,
      contrast: theme.palette[chipProps.color].light as string,
      text: theme.palette[chipProps.color].contrastText as string
    } as Tier;
  }) as Palette,
  [MatrixPaletteOption.Red]: [
    { background: '#663434', contrast: '#4c2727', text: theme.palette.error.main },
    { background: '#994d4d', contrast: '#804141', text: '#fff' },
    { background: '#cc6767', contrast: '#b35a5a', text: '#000' },
    { background: '#ff8181', contrast: '#e67474', text: '#000' },
    { background: '#ff9a9a', contrast: '#ff8e8e', text: '#000' },
    { background: '#ffb3b3', contrast: '#ffa7a7', text: '#000' }
  ],

  [MatrixPaletteOption.Blue]: [
    { background: '#004c66', contrast: '#00394c', text: theme.palette.info.main },
    { background: '#007399', contrast: '#006080', text: '#fff' },
    { background: '#0099cc', contrast: '#0086b3', text: '#000' },
    { background: '#00bfff', contrast: '#00ace6', text: '#000' },
    { background: '#33ccff', contrast: '#1ac5ff', text: '#000' },
    { background: '#66d9ff', contrast: '#4dd2ff', text: '#000' }
  ]
};

export const HeatMapOptions = [
  { label: 'Detection Count', value: HeatMap.Detection },
  { label: 'Threat Profile Priority', value: HeatMap.ThreatProfile }
];
