import React from 'react';

import isEmpty from 'lodash/isEmpty';

import Placeholder from 'snap-ui/Placeholder';

import useAnalyticRank from 'aso/useAnalyticRank';

import { useMayI } from 'module/May';
import { SupplementalArtifact } from 'module/Search';
import ConfidenceScoreDetails from 'module/Widgets/ConfidenceScoreDetails';
import { LevelMenuBadge } from 'module/Widgets/LevelMenuBadge/LevelMenuBadge';

import { useAuth } from 'provider';

import { Status } from 'storage';

import { FunctionalPermission } from 'types/auth';
import { Artifact, ArtifactScore, ArtifactScoreDetail } from 'types/common';

import { getScoreDetails } from 'utilities/ArtifactUtils';

interface Props {
  analytic: Artifact;
  supplemental: SupplementalArtifact;
}

export default function AnalyticConfidence({ analytic, supplemental }: Props): JSX.Element {
  const { defaultOrgId } = useAuth();
  const { setRank, status } = useAnalyticRank(analytic.guid);
  const [ranks, setRanks] = React.useState<ArtifactScoreDetail[]>([]);
  const isPending = Status.pending === status;

  React.useEffect(() => {
    setRanks(supplemental?.ranks);
  }, [supplemental?.ranks]);

  const permitted = useMayI(FunctionalPermission.SetAnalyticMeta);

  const confidenceScoreDetail = getScoreDetails(defaultOrgId, ranks);

  return (
    <>
      {isEmpty(analytic) || isEmpty(supplemental) ? (
        <Placeholder height={48} width={132} variant='rectangular' />
      ) : (
        <LevelMenuBadge
          value={confidenceScoreDetail?.rank}
          variant='Confidence'
          permitted={permitted}
          loading={isPending}
          handleLevelChange={handleChange}
          tooltip={<ConfidenceScoreDetails confidenceScoreDetail={confidenceScoreDetail} />}
        />
      )}
    </>
  );

  async function handleChange(rank: ArtifactScore): Promise<void> {
    setRank(rank).then(() => {
      const index = ranks.findIndex(rank => rank.organization_id === defaultOrgId);
      const isFound = ranks.find(rank => rank.organization_id === defaultOrgId);
      if (isFound) {
        ranks[index] = { ...ranks[index], organization_id: defaultOrgId, rank };
        setRanks([...ranks]);
      } else {
        setRanks([...ranks, { organization_id: defaultOrgId, rank }]);
      }
    });
  }
}
