import React from 'react';

import classNames from 'classnames';
import { Link } from 'react-router-dom';

import Box from 'snap-ui/Box';
import { styled } from 'snap-ui/util';

import Path from 'constants/paths';

import { useFilterRegistry } from 'module/GlobalFilter';
import { MatrixPaletteOption } from 'module/Scaffold/Scaffold.type';
import { Discriminator } from 'module/Tag';

import { ArtifactType } from 'types/common';

import MatrixCount from './Matrix.count';
import Tooltip from './Matrix.tooltip';
import { HeatMap, StatisticNode } from './Matrix.type';
import { getFilterValuesOnlyQueryString, getNodeSpecificName, nodeTotal } from './Matrix.util';
import { Id } from './Tactic/Tactic';
import { ContainerProps, TechniqueName } from './Technique/Technique';
import useMatrixPalette from './useMatrixPalette';

type Props = {
  node: StatisticNode;
  className: string;
  showMitreId: boolean;
  showCount: boolean;
  showEmpty: boolean;
  topic: ArtifactType;
  palette: MatrixPaletteOption;
  heatMap: HeatMap;
  maxRange: number;
  perBucket: number;
};

const Container = styled('div', { shouldForwardProp: p => p !== 'tier' && p !== 'background' })<ContainerProps>`
  background-color: ${p => p.background || p.theme.palette.grey[800]};
  margin: 0 8px 2px;
  padding: ${p => p.theme.spacing(2, 3, 2, 2)};

  border-left-style: solid;
  border-left-width: 8px;
  border-left-color: ${p => p.tier || p.theme.palette.grey[700]};

  &.rounded-bottom {
    border-radius: ${p => p.theme.spacing(0, 0, 1, 1)};
  }
`;

const SubTechniqueNameWrapper = styled('div')`
  padding-bottom: ${p => p.theme.spacing(1)};
`;

function SubTechnique({
  className,
  node,
  showMitreId,
  showCount,
  showEmpty,
  topic,
  palette,
  heatMap,
  maxRange,
  perBucket
}: Props) {
  const { values } = useFilterRegistry(topic);

  const name = getNodeSpecificName(node);
  const { getTier } = useMatrixPalette(palette, heatMap, maxRange, perBucket);
  const tier = getTier(node);
  const total = nodeTotal(node);
  if (!showEmpty && total === 0) return null;

  return (
    <Tooltip count={total} mitre_id={node.mitre_id}>
      <Container className={classNames(className, 'brighten')} tier={tier?.contrast} background={tier?.background}>
        <SubTechniqueNameWrapper>
          <Link
            target='_blank'
            to={{
              pathname: `${Path.Collection}/${Discriminator.Attack}/${encodeURIComponent(
                node.name
              )}${getFilterValuesOnlyQueryString(values, window.location.search)}`
            }}
          >
            <TechniqueName variant='body2' tier={tier?.text}>
              {name}
            </TechniqueName>
            <Box sx={{ minHeight: showMitreId || showCount ? '1.5rem' : 0 }}>
              {showMitreId && <Id tier={tier?.text}>{node.mitre_id}</Id>}
              {showCount && (
                <Box sx={{ float: 'right' }}>
                  <MatrixCount count={total} topic={topic} tier={tier} />
                </Box>
              )}
            </Box>
          </Link>
        </SubTechniqueNameWrapper>
      </Container>
    </Tooltip>
  );
}

export default SubTechnique;
