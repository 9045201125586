import React from 'react';

import { FormikErrors } from 'formik';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import { Link } from 'react-router-dom';

import CircularProgress from 'snap-ui/CircularProgress';
import { FormDialog } from 'snap-ui/Dialog';
import { FormHelperText } from 'snap-ui/FormControl';
import Placeholder from 'snap-ui/Placeholder';
import { styled } from 'snap-ui/util';

import { CancelToken } from 'apis/snapattack';

import useAgents from 'aso/useAgents';
import { useFeedAsync } from 'aso/useFeed';

import Path from 'constants/paths';

import { ApiError } from 'module/ApiError';
import { OSName } from 'module/BAS/BAS.type';
import AgentField from 'module/BAS/Launcher/AgentField';
import { standardFormikBaseProps } from 'module/Form/Form.const';
import RadioGroupFormik from 'module/Form/RadioGroupFormik';
import SelectFormik from 'module/Form/SelectFormik';
import { mapIntegrationToOption } from 'module/Integration/Integration.util';
import { IntegrationAutocompleteFormik } from 'module/Integration/IntegrationAutocomplete';
import { JobType, postJobs } from 'module/Job';

import { useAuth, usePushSnack } from 'provider';
import { useIntegrationCatalog } from 'provider/Integration';

import { Status, useAsync } from 'storage';

import { ArtifactType, BooleanString, Guid } from 'types/common';
import { Ops, Query } from 'types/filter';

import { renderValueWithLabel } from 'utilities/TextUtils';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  gap: ${p => p.theme.spacing(7)};
  min-height: 198px;

  .row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: ${p => p.theme.spacing(7)};
  }

  .Agent-FieldContainer,
  .Agent-FieldContainer a {
    align-items: inherit;
  }
`;

export type SimulateAttackScriptFormInput = {
  agent: Guid;
  integration: Guid;
  generate_only: BooleanString;
  validate: string;
};

export const SimulateAttackScriptFormInitial: SimulateAttackScriptFormInput = {
  agent: '',
  generate_only: BooleanString.True,
  integration: '',
  validate: ''
};

export type SimulateAttackScriptsModal = {
  isOpen: boolean;
  onClose: () => void;
  query: Query;
};

export function SimulateAttackScriptsModal(props: SimulateAttackScriptsModal) {
  const { defaultOrgId } = useAuth();
  const pushSnack = usePushSnack();

  const { isOpen, onClose } = props;
  const { status: integrationStatus, integrations } = useIntegrationCatalog();
  const agentInterface = useAgents();

  const query = React.useMemo(
    () => ({
      op: Ops.and,
      items: [
        props.query,
        {
          field: 'is_simulatable',
          op: Ops.equals,
          value: 'true'
        }
      ]
    }),
    [props.query]
  );

  const {
    fetchFeed,
    status: feedStatus,
    items: selections
  } = useFeedAsync(ArtifactType.Analytic, isOpen ? query : undefined, {
    page: 1,
    size: 5000
  });
  const { task, status: actingStatus, errorProps } = useAsync();

  const generateOnlyDisabled = selections?.length <= 1;

  const displayTitle = `Create a Simulated Attack Script for ${renderValueWithLabel(selections?.length, 'Detection')}`;
  const disabled = actingStatus === Status.pending || isEmpty(selections);

  const isActing = [actingStatus, integrationStatus, feedStatus].includes(Status.pending);

  const integrationOptions = React.useMemo(
    () =>
      integrations.huntable
        .filter(i => i.type !== 'CustomerManaged')
        .filter(i => i?.guid)
        .map(i => omit(mapIntegrationToOption(i, undefined, 'guid'), 'group')),
    [integrations.huntable]
  );

  React.useEffect(() => {
    const cancelSource = CancelToken.source();
    if (isOpen && query) fetchFeed(cancelSource);
    else cancelSource.cancel();
    return () => cancelSource.cancel();
  }, [fetchFeed, query, isOpen]);

  function handleSubmit(values: SimulateAttackScriptFormInput) {
    const extra_parameters = {
      generate_only: Boolean(values.generate_only),
      skip_validation: values.validate === 'skip'
    };

    const payload = {
      organization_id: defaultOrgId,
      type: JobType.Simulate,
      guids: selections.map(i => i.guid),
      parameters: extra_parameters,
      integrations: values.integration ? [values.integration] : [],
      agents: values.agent ? [values.agent] : []
    };

    task(postJobs(payload)).then(() => {
      onClose();
      pushSnack(
        <>
          Simulate Attack Scripts job created. Monitor progress on the{' '}
          <Link
            to={{
              pathname: Path.Tasks
            }}
          >
            Tasks
          </Link>{' '}
          page.
        </>,
        'info',
        'center',
        'bottom',
        5000
      );
    });
  }

  return (
    <FormDialog<SimulateAttackScriptFormInput>
      title={displayTitle}
      DialogProps={{ open: isOpen, onClose, maxWidth: 'sm' }}
      SubmitProps={{
        children: isActing ? <CircularProgress size={25} /> : 'Submit',
        disabled: disabled || isEmpty(selections)
      }}
      FormikConfig={{
        ...standardFormikBaseProps,
        validateOnChange: true,
        initialValues: SimulateAttackScriptFormInitial,
        validate: (values: SimulateAttackScriptFormInput): FormikErrors<SimulateAttackScriptFormInput> | undefined => {
          const environmentFieldsRequired =
            values.generate_only === BooleanString.False || values.validate === 'environment';

          if (!values.validate) return { validate: 'Select a Validation Type' };
          if (environmentFieldsRequired) {
            if (!values.agent) return { agent: 'A Target Machine is required to validate in your environment' };
            if (!values.integration)
              return { integration: 'An Integration is required to validate in your environment.' };
          }
        },
        onSubmit: handleSubmit
      }}
    >
      {({ values, setValues }) => {
        const validateOptions = [
          { value: 'environment', content: 'Validate in my environment' },
          { value: 'virtual', content: 'Validate with a virtual machine' },
          { value: 'skip', content: 'Skip validation' }
        ].filter(option => values.generate_only === BooleanString.True || option.value !== 'virtual');

        const environmentFieldsRequired =
          values.generate_only === BooleanString.False || values.validate === 'environment';

        function handleValidateChange(validate: string): void {
          if (validate === 'virtual') {
            setValues({
              ...values,
              validate,
              agent: '',
              integration: ''
            });
          }
        }

        return (
          <Container>
            {feedStatus === Status.pending ? (
              <>
                <Placeholder variant='rectangular' height={40} width='100%' />
                <Placeholder variant='rectangular' height={40} width='100%' />
                <Placeholder variant='rectangular' height={40} width='100%' />
              </>
            ) : (
              <>
                <ApiError {...errorProps} />
                <div>
                  <RadioGroupFormik
                    disabled={generateOnlyDisabled}
                    label='Job Execution Type'
                    name='generate_only'
                    options={[
                      { label: 'Only Generate Missing Attack Scripts', value: BooleanString.True },
                      { label: 'Generate Missing Attack Scripts and Run Attack Simulation', value: BooleanString.False }
                    ]}
                  />
                  {generateOnlyDisabled && (
                    <FormHelperText>
                      Attack simulations can only be executed with more than one detection.
                    </FormHelperText>
                  )}
                </div>
                <SelectFormik
                  className='Form-field required'
                  label='Validation Type'
                  name='validate'
                  options={validateOptions}
                  onChange={handleValidateChange}
                />

                {environmentFieldsRequired && (
                  <>
                    <AgentField
                      agentInterface={agentInterface}
                      name='agent'
                      target_platforms={[OSName.Windows, OSName.Linux]}
                    />
                    <IntegrationAutocompleteFormik
                      className={'Form-field required'}
                      elevated
                      disableClearable
                      label='Integration'
                      name='integration'
                      options={integrationOptions}
                    />
                  </>
                )}
              </>
            )}
          </Container>
        );
      }}
    </FormDialog>
  );
}
