import { GridSortModel } from 'snap-ui/DataGrid';

import { Page } from 'module/Feed/Feed.type';

import { ErrorProps, Status } from 'storage';

import { Language } from 'types/analytic';
import { ArtifactScore, Guid, Ident, Identity, Tracked } from 'types/common';

export enum JobType {
  Hunt = 'Hunt',
  Rank = 'Rank',
  IOC = 'IOC',
  Import = 'Import',
  Simulate = 'Simulate',
  Test = 'Test'
}

export const JobTypeTableMap: Record<JobType, string> = {
  [JobType.Hunt]: 'Hunt',
  [JobType.Rank]: 'Confidence Tailoring',
  [JobType.IOC]: 'IOC',
  [JobType.Import]: 'Imported',
  [JobType.Simulate]: 'AI Attack Script',
  [JobType.Test]: 'Test'
} as const;

export type JobTypeTableMap = keyof typeof JobTypeTableMap;

export type JobOverviewFilters = {
  sort?: string;
  org_id?: number;
  name?: string;
  integration_guid?: Guid;
  days?: number;
};

export type JobOverviewFeed = Page<JobOverview>;

export type JobOverviewFeedAsync = JobOverviewFeed & {
  status: Status;
  error: ErrorProps;
};

export enum JobStatus {
  Pending = 'Pending',
  Started = 'Started',
  Failed = 'Failed',
  CompletedWithErrors = 'CompletedWithErrors',
  Success = 'Success',
  Canceled = 'Canceled',
  Paused = 'Paused'
}

export type AdHocQueryPayload = {
  organization_id: number;
  type: JobType;
  integrations: string[];
  adhoc_queries: {
    language: Language['backend_key'];
    search: string;
  }[];
  parameters?: Record<string, unknown>;
};

export type SimulateJobPayload = {
  agents?: string[];
  guids: string[];
  integrations?: string[];
  parameters: Record<string, unknown>;
  type: JobType.Simulate;
};

export type PostJobsInput =
  | {
      organization_id?: number;
      name?: string;
      guids: string[];
      parameters: Record<string, unknown>;
      type: JobType;
      expire_time?: string;
      integrations: string[];
    }
  | AdHocQueryPayload
  | SimulateJobPayload;

export type Job = Tracked & {
  name: string;
  permission: string[];
  items: Identity[];
  parameters: Record<string, unknown>;
  deleted: boolean;
  type: JobType;
  integration: Tracked;
  status: JobStatus;
  status_detail?: string;
  creation: string;
  modified: string;
};

export type JobGroup = Tracked & {
  name: string;
  permission: string[];
  jobs: Tracked[];
  schedule_id?: number;
  status: JobStatus;
};

export type JobHuntOverview = {
  hunt_name: string;
  date: string;
  status: JobStatus;
  detections: number;
  hits: number | null;
  integration: string;
  job_guid: string;
};

export type JobOverviewDetailItem = Identity & {
  hit_count: number;
  item_state_detail?: string;
  item_error?: string;
  integration_name: string;
  integration_guid: Guid;
};

export type JobOverview = {
  name?: string;
  creation?: string;
  modified?: string;
  analytic_job_group_id: number;
  analytic_job_group_guid: string;
  analytic_job_guid: string;
  analytic_job_id: number;
  total_hits?: number;
  schedule_id?: number;
  type: JobTypeTableMap;
  created_by?: string;
  created_by_id?: number;
  integration_guid: string;
  integration_name: string;
  job_status: JobStatus;
  job_status_detail?: string;
  job_item_count?: number;
  detections_completed?: number;
  dispatch_completed?: number;
  detections_dispatched?: number;
  detections_failed?: number;
  progress_percent?: number;
  estimated_remaining_duration?: string; //ISO8601 Duration (Time)
  parameters?: Record<string, unknown>;
  jobsByGroup?: string[];
  organization_id: number;
  complete?: boolean;
  stopped?: boolean;
  can_pause?: boolean;
  can_resume?: boolean;
  can_cancel?: boolean;
  is_external?: boolean;
  expired?: boolean;

  /* Simulation Job fields */
  generations_attempted?: number;
  generations_validated?: number;
  generations_unsupported?: number;
  generations_draft?: number;
};

export type JobOverviewDetail = JobOverview & {
  item_detail: JobOverviewDetailItem[];
};

export type JobResult = {
  dispatch_count: number;
  dispatch_completed: number;
  dispatch_errors: any;
  total_job_duration: number;
  total_event_count: number;
  host_count: number;
  modified: string;
};

export type JobGroupResult = Tracked & {
  name: string;
  bas_job_id: string;
  job_hit_count: number;
  count: number;
  error: string;
  integration: string;
  integration_name: string;
  old_rank: ArtifactScore;
  logsource: string;
  diff?: number;
  groupGuid?: string[];
  newRank: ArtifactScore;
};

export type TaskState = {
  typeFilter: JobType;
  orgFilter: Ident;
  nameFilter: string;
  name: string;
  sortModel: GridSortModel;
  pageParams: {
    page: number;
    size: number;
  };
};

export type TaskAction =
  | { type: 'SET_TYPE'; payload: JobType }
  | { type: 'SET_PAGE'; payload: number }
  | { type: 'SET_ORG_FILTER'; payload: Ident }
  | { type: 'SET_NAME_FILTER'; payload: string }
  | { type: 'SET_DEBOUNCED_NAME'; payload: string }
  | { type: 'SET_SORT_MODEL'; payload: GridSortModel }
  | { type: 'RESET_FILTER'; payload: JobType };
