import omit from 'lodash/omit';
import pick from 'lodash/pick';

import { EditorLanguage, LanguageExtended, LanguageTargetPayload } from 'types/analytic';
import { Ident } from 'types/common';

export function transformLanguagePayload(values: EditorLanguage): LanguageTargetPayload {
  const payload: LanguageTargetPayload = omit(values, [
    'id',
    '_source_language_id',
    '_clone_configs',
    'backend_options'
  ]);
  payload.backend_options = values.backend_options?.map(({ field, value }) => `${field}=${value}`);
  return payload;
}

export function pickEditorValues(language: LanguageExtended, orgId?: Ident): Partial<EditorLanguage> {
  return {
    ...pick(language, ['discriminator', 'name', 'backend_key', 'output_format', 'config_keys']),
    backend_options: language.backend_options?.map(opt => {
      const [field, value] = opt.split('=');
      return { field, value: value ?? '' };
    }),
    id: orgId ? undefined : language.id,
    organization_id: orgId ?? language.organization.id,
    _clone_configs: false,
    _source_language_id: orgId ? language.id : undefined
  };
}
