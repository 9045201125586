import { Landing, HyperTag } from './Landing.type';

export const LANDING_SHELL: Landing = {
  combined: {} as HyperTag,
  items: [],
  analytic_filter: null,
  session_filter: null,
  threat_intelligence_filter: null,
  collection_filter: null,
  bas_script_filter: null
};
