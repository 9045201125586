import React from 'react';

import { Status, useAsync } from 'storage';

import { extractIndicatorsFromText, extractIndicatorsFromURL } from '../IOC.api';
import { ExtractIOCResponse, IOC } from '../IOC.type';
import useAnnotateIOCs from './useAnnotateIOCs';

type ExtractIOCInterface = {
  extractText(text: string): void;
  extractUrl(url: string): void;
  iocs: IOC[];
  annotatedIOCs: IOC[];
  extractStatus: Status;
  text: string;
};

const ExtractIOCShell: ExtractIOCResponse = {
  text: '',
  indicators: []
};

export default function useExtractIOC(): ExtractIOCInterface {
  const { data, run, status } = useAsync<ExtractIOCResponse>(ExtractIOCShell);
  const { annotatedIOCs, annotateIOCsWithWarnings } = useAnnotateIOCs();

  const extractText = React.useCallback(
    (text: string) => {
      if (text) run(extractIndicatorsFromText(text));
    },
    [run]
  );

  const extractUrl = React.useCallback(
    (url: string) => {
      if (url) run(extractIndicatorsFromURL(url));
    },
    [run]
  );

  React.useEffect(() => {
    if (data.indicators.length > 0) annotateIOCsWithWarnings(data.indicators);
  }, [annotateIOCsWithWarnings, data.indicators]);

  return {
    extractText,
    extractUrl,
    text: data.text,
    iocs: data.indicators,
    annotatedIOCs,
    extractStatus: status
  };
}
