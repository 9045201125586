import { Guid } from 'types/common';

type SimpleLineChartObj = {
  value: number;
};
export type SimpleLineChartData = SimpleLineChartObj[];

type DoubleLineChartObj = {
  value: number;
  date: string;
};
export type DoubleLineChartData = DoubleLineChartObj[];

type PieChartObj = {
  name: string;
  value: number;
};
export type PieChartData = PieChartObj[];

export type ActionGraph = number[][];

type CoverageType = {
  children: number;
  coverage: number;
  tag_id: number;
  tag_name: string;
};

export type RadarData = {
  name: string;
  values: Record<string, number>;
};

export type DefaultColorObj = {
  contrastText?: string;
  dark?: string;
  light?: string;
  main?: string;
};

export type SocDashboardStatObj = {
  action_graph: ActionGraph;
  confidence_high: number;
  confidence_highest: number;
  confidence_low: number;
  confidence_lowest: number;
  confidence_medium: number;
  confidence_unknown: number;
  deployments: number;
  hits: number;
  integration_id: number;
  organization_id: number;
  score: number;
  severity_high: number;
  severity_highest: number;
  severity_low: number;
  severity_lowest: number;
  severity_medium: number;
  severity_unknown: number;
  time: string;
  coverage?: CoverageType[];
  untesteds: number;
  unvalidations: number;
  validation_gaps: number;
  validations: number;
};

type SocDashboardStatKey = string | 'all';

export interface SocDashboardStats {
  [key: SocDashboardStatKey]: SocDashboardStatObj[];
}

type ViewedStat = {
  time: Date;
  intel: number;
  threats: number;
  detections: number;
};
export type ViewedStats = ViewedStat[];

type HuntedStat = {
  time: Date;
  hunts: number;
  hunts_ioc: number;
};
export type HuntedStats = HuntedStat[];

type CreatedStat = {
  time: Date;
  intel: number;
  threats: number;
  detections: number;
  simulations: number;
  simulations_failed: number;
  deployments: number;
};
export type CreatedStats = CreatedStat[];

export enum EVERY {
  DAY = '1d',
  WEEK = '1w',
  MONTH = '1 month'
}

export enum DEPLOYED_DETECTION_TYPES {
  MONITOR = 'Monitor',
  ALERT = 'Alert',
  LOG = 'Log',
  HUNT = 'Hunt'
}

export type Stats = {
  data: SocDashboardStats;
  every: EVERY;
  organization_id: number;
  start: Date;
  stop: Date;
};

export type Hits = {
  integration_guid: Guid;
  integration_hit_count: number;
  integration_hits: {
    hits: number;
    day: string;
  }[];
};
