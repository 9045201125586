import React from 'react';

import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import { z } from 'zod';

import CircularProgress from 'snap-ui/CircularProgress';
import { FormDialog } from 'snap-ui/Dialog';
import { FieldsLayout } from 'snap-ui/Layout';
import { styled } from 'snap-ui/util';

import { ARTIFACT_SCORE_OPTIONS } from 'constants/common';

import { standardFormikBaseProps } from 'module/Form';
import AutocompleteFormik from 'module/Form/AutocompleteFormik';
import TextFieldFormik from 'module/Form/TextFieldFormik';
import { RedMarkerCreationPayload, RedMarkerUpdatePayload } from 'module/Session/Session.type';
import { Discriminator, TagAutocompleteFormik } from 'module/Tag';

import { isRedMarker, MarkerExtended, RedMarkerExtended } from 'types/marker';

import { addDurationISOFormatted, convertSecondsToMMss } from 'utilities/TimeUtils';

const FormContainer = styled(FieldsLayout)`
  .row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: ${p => p.theme.spacing(3)};
  }
`;

const MarkerFormSchema = z.object({
  description: z.string().min(1, 'Description is required'),
  attack_names: z.array(z.string()).min(1, 'At least one technique is required'),
  actor_names: z.array(z.string()).optional(),
  software_names: z.array(z.string()).optional(),
  vulnerability_names: z.array(z.string()).optional(),
  severity: z.string().min(1, 'Severity is required'),
  time: z.string().min(1, 'Time is required')
});

export type MarkerFormInput = Partial<z.infer<typeof MarkerFormSchema>>;

export type RedMarkerModal = {
  isOpen: boolean;
  onClose: () => void;
  className?: string;
  onDelete?: (marker: RedMarkerExtended) => Promise<void>;
  onAdd?: (payload: RedMarkerCreationPayload) => Promise<void>;
  onUpdate?: (marker: RedMarkerExtended, payload: RedMarkerUpdatePayload) => Promise<void>;
  newMarker?: boolean;
  offset?: number;
  startTime?: string;
  marker?: MarkerExtended;
};

export function RedMarkerModal(props: RedMarkerModal) {
  const { isOpen, marker, newMarker, offset, onAdd, onClose, onDelete, onUpdate } = props;
  const [isActing, setIsActing] = React.useState<boolean>(false);
  const existingRedMarker = isRedMarker(marker);
  const displayTitle = existingRedMarker ? 'Update Attack' : 'Label Attack';

  async function handleDelete() {
    setIsActing(true);

    try {
      if (typeof onDelete === 'function' && existingRedMarker) {
        await onDelete(marker);
      }
    } finally {
      setIsActing(false);
      onClose();
    }
  }

  async function handleSubmit(data: MarkerFormInput) {
    setIsActing(true);

    const payload: RedMarkerCreationPayload = {
      timestamp: addDurationISOFormatted(props.startTime, offset),
      description: data.description,
      attack_names: data.attack_names,
      actor_names: data.actor_names,
      software_names: data.software_names,
      vulnerability_names: data.vulnerability_names,
      severity: data.severity
    };

    try {
      if (typeof onUpdate === 'function' && existingRedMarker) {
        await onUpdate(marker, omit(payload, 'timestamp'));
      } else if (typeof onAdd === 'function') {
        if (marker?.event) payload.event_id = marker.event.id;
        await onAdd(payload);
      }
    } finally {
      setIsActing(false);
      onClose();
    }
  }

  return (
    <FormDialog
      title={displayTitle}
      DialogProps={{ open: isOpen, onClose, maxWidth: 'sm' }}
      SubmitProps={{
        children: isActing ? <CircularProgress size={25} /> : existingRedMarker ? 'Update' : 'Add',
        disabled: isActing
      }}
      SecondaryActionProps={
        existingRedMarker
          ? {
              children: isActing ? <CircularProgress size={25} /> : 'Delete',
              disabled: isActing,
              onClick: handleDelete
            }
          : null
      }
      FormikConfig={{
        ...standardFormikBaseProps,
        validateOnChange: true,
        initialValues: {
          time: convertSecondsToMMss(offset),
          severity: '',
          description: '',
          attack_names: [],
          ...pick(
            marker,
            'time',
            'severity',
            'description',
            'attack_names',
            'actor_names',
            'software_names',
            'vulnerability_names'
          )
        },
        onSubmit: handleSubmit,
        zodSchema: MarkerFormSchema
      }}
    >
      <FormContainer className={props.className} spacing={7}>
        <div className='row'>
          <TextFieldFormik
            data-testid='time'
            label='Time'
            name='time'
            required
            disabled={existingRedMarker || (newMarker && !isEmpty(marker))}
          />
          <AutocompleteFormik
            label='Severity'
            name='severity'
            options={ARTIFACT_SCORE_OPTIONS}
            disableUserAdditions
            disableClearable
            required
            testId='severity'
          />
        </div>
        <TextFieldFormik
          label='Description'
          name='description'
          multiline
          maxRows={4}
          required
          data-testid='description'
        />
        <TagAutocompleteFormik name='attack_names' multiple discriminator={Discriminator.Attack} required />
        <TagAutocompleteFormik name='actor_names' multiple discriminator={Discriminator.Actor} />
        <TagAutocompleteFormik name='software_names' multiple discriminator={Discriminator.Software} />
        <TagAutocompleteFormik name='vulnerability_names' multiple discriminator={Discriminator.Vulnerability} />
      </FormContainer>
    </FormDialog>
  );
}
