import React from 'react';

import isEmpty from 'lodash/isEmpty';

import { Option } from 'snap-ui/Autocomplete';

import useDebounce from 'hooks/useDebounce';

import { Status } from 'storage';

import { Discriminator, MiniTag, sortTag } from '.';
import useTag from './useTag';

export type TagOption = Option & {
  discriminator: Discriminator;
  sigma_names: string[];
  tags?: MiniTag[];
};

type TagOptionInterface = {
  options: TagOption[];
  search(partial: string): void;
  searching: boolean;
  searchTerm: string;
  setSearchTerm(value: string): void;
};

export default function useTagOptions(
  discriminator: Discriminator,
  opts?: {
    includeTagNames?: boolean;
    includeSigma?: boolean;
  }
): TagOptionInterface {
  const { includeTagNames, includeSigma } = opts || {};
  const { data, search, status } = useTag(discriminator);
  const [searchTerm, setSearchTerm] = React.useState('');
  const [_effectiveSearchTerm, setEffectiveSearchTerm] = React.useState(searchTerm);
  const effectiveSearchTerm = useDebounce(_effectiveSearchTerm);

  React.useEffect(() => {
    if (searchTerm.length > 2) setEffectiveSearchTerm(searchTerm);
    else setEffectiveSearchTerm('');
  }, [searchTerm, setEffectiveSearchTerm]);

  React.useEffect(() => {
    if (search) search(effectiveSearchTerm);
  }, [search, effectiveSearchTerm]);

  const options = React.useMemo(
    () =>
      data.sort(sortTag).map<TagOption>(value => {
        let filteredTagNames = value.tags?.filter(t => t.name !== value.name).map(t => t.name);
        filteredTagNames = [...new Set(filteredTagNames)];
        const tagNames = isEmpty(filteredTagNames) ? '' : ` (${filteredTagNames.join(', ')})`;
        const sigmaNames = isEmpty(value.sigma_names) ? '' : ` (${value.sigma_names.join(', ')})`;

        return {
          label: includeSigma
            ? `${value.name}${sigmaNames}`
            : includeTagNames
            ? `${value.name}${tagNames}`
            : value.name,
          content: includeSigma
            ? `${value.name}${sigmaNames}`
            : includeTagNames
            ? `${value.name}${tagNames}`
            : value.name,
          value: value.name,
          discriminator: value.discriminator,
          sigma_names: value.sigma_names,
          tags: value.tags
        };
      }),
    [data, includeTagNames, includeSigma]
  );

  return {
    options,
    search,
    searching: status === Status.pending,
    searchTerm,
    setSearchTerm
  };
}
