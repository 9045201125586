import React from 'react';

import { CancelToken } from 'apis';

import { useAsync } from 'storage';

import { NOOP } from 'utilities/FunctionUtils';

import { getSigmaTagsByDiscriminator } from './Tag.service';
import { Discriminator, SigmaTag } from './Tag.type';

function useTag(discriminator: Discriminator, limit = 50) {
  const { data, setData, status, task } = useAsync<SigmaTag[]>([]);
  const cancelTokenSourceRef = React.useRef(CancelToken.source());

  const search = React.useCallback(
    (partial: string) => {
      cancelTokenSourceRef.current.cancel();
      const source = CancelToken.source();
      cancelTokenSourceRef.current = source;
      task(getSigmaTagsByDiscriminator(limit, discriminator, { partial }, { cancelToken: source.token })).then(
        d => setData(d),
        NOOP
      );
    },
    [discriminator, limit, setData, task]
  );

  return {
    data,
    search,
    status
  };
}

export default useTag;
