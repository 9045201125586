import React from 'react';

import { Formik, FormikErrors } from 'formik';
import { Link } from 'react-router-dom';

import { Option } from 'snap-ui/Autocomplete';
import Button from 'snap-ui/Button';
import Icon from 'snap-ui/Icon';
import Tooltip from 'snap-ui/Tooltip';
import { styled, useTheme } from 'snap-ui/util';

import useSSO from 'aso/useSSO';

import Path from 'constants/paths';

import AutocompleteFormik from 'module/Form/AutocompleteFormik';
import TextFieldFormik from 'module/Form/TextFieldFormik';

import { PasswordType, pwValidation, registerSchema } from 'services/pwValidationService';

import { Status } from 'storage';

import { RegisterFormData, RegisterRequest } from 'types/auth';

import { Form } from '../Authentication.style';
import LoginNextForm from '../Login/LoginNext';
import RegisterAgreement from './Register.agreement';

const UlFormError = styled('ul')`
  list-style: none;
  padding-left: 0;
  margin-top: 0;

  &.raw-dropdown > .icon.error-icon {
    right: ${p => p.theme.spacing(6)};
  }
`;

type RegisterFormProps = {
  initialValues: Partial<RegisterFormData>;
  submitForm: (data: RegisterRequest) => void;
  tokenError: boolean;
  disabledEmail?: boolean;
};

// These are enumerated on the backend
const interests = [
  'Detection Engineer',
  'Threat Hunter',
  'Incident Responder',
  'Red Team',
  'CTI Analyst',
  'Manager / Director'
];

const interestOptions: Option[] = interests.map(key => ({
  content: key,
  value: key
}));

const RegisterForm = ({
  initialValues,
  submitForm,
  tokenError,
  disabledEmail
}: RegisterFormProps): React.ReactElement => {
  const { palette } = useTheme();

  const sso = useSSO();
  const [email, setEmail] = React.useState<string>(initialValues.email || '');
  const displayNameRef = React.useRef();

  React.useEffect(() => {
    const displayName = displayNameRef?.current as any;
    if (sso.status === Status.resolved && displayName) {
      displayName.focus();
    }
  }, [sso.status, displayNameRef]);

  const handleSubmit = (data: RegisterFormData): void => {
    submitForm(data as RegisterRequest);
  };

  return (
    <>
      {sso.status !== Status.resolved && !initialValues.email && (
        <LoginNextForm
          initialValues={{
            email,
            token: initialValues.token || ''
          }}
          sso={sso}
          setEmail={setEmail}
          tokenError={tokenError}
        />
      )}
      <Formik
        enableReinitialize={true}
        initialValues={{
          email: email || initialValues.email || '',
          name: initialValues.name || '',
          first_name: initialValues.first_name || '',
          last_name: initialValues.first_name || '',
          password: initialValues.password || '',
          confirm_password: initialValues.password || '',
          company: initialValues.company || '',
          job_title: initialValues.job_title || '',
          interest: initialValues.interest || '',
          token: initialValues.token || '',
          legal: false
        }}
        onSubmit={handleSubmit}
        validate={(values: RegisterFormData): FormikErrors<RegisterFormData> => {
          const errors = pwValidation(PasswordType.PASSWORD, values.password);
          if (values.confirm_password && values.confirm_password !== values.password) {
            errors.confirm_password = 'Passwords must match';
          }
          return errors;
        }}
        validationSchema={registerSchema}
      >
        {({ errors, touched, handleChange }) => (
          <Form>
            {(sso.isNotSSO || initialValues.email) && (
              <>
                <TextFieldFormik
                  label='Email Address'
                  name='email'
                  disabled={disabledEmail}
                  required={tokenError || !initialValues.token}
                />
                <Tooltip
                  title='This is how you will be seen by other users of the application. You may use an alias/handle.'
                  placement='right'
                  arrow
                >
                  <>
                    <TextFieldFormik label='Display Name' name='name' inputRef={displayNameRef} required />
                    <Icon.Info className='Form-info' color='info' />
                  </>
                </Tooltip>
                <TextFieldFormik label='First Name' name='first_name' required />
                <TextFieldFormik label='Last Name' name='last_name' required />
                <TextFieldFormik label='Password' name={PasswordType.PASSWORD} type={PasswordType.PASSWORD} required />
                <TextFieldFormik
                  label='Confirm Password'
                  name={PasswordType.CONFIRM_PASSWORD}
                  type={PasswordType.PASSWORD}
                  required
                />

                <AutocompleteFormik label='Interest' name='interest' options={interestOptions} />
                <TextFieldFormik label='Company' name='company' />
                <TextFieldFormik label='Job Title' name='job_title' />
                <RegisterAgreement onChange={handleChange}>
                  {errors.legal && touched.legal ? (
                    <UlFormError>
                      <li key={errors.legal}>
                        <span className='error' style={{ marginLeft: '32px', color: palette.error.main }}>
                          {errors.legal}
                        </span>
                      </li>
                    </UlFormError>
                  ) : null}
                </RegisterAgreement>

                <Button id='PrimaryRegisterButton' ariaLabel='Primary registration button' type='submit'>
                  Register
                </Button>
              </>
            )}

            <div>
              <div className='login-link'>
                Already have an account?&nbsp;&nbsp;
                <Link to={Path.Login}>Log in</Link>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default RegisterForm;
