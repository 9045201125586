import { useMemo } from 'react';

import useAnalytic, { AnalyticLoadState } from 'aso/useAnalytic';

import { Processor } from 'module/HighlightDetection';
import { SupplementalArtifact } from 'module/Search';
import { SigmaTag, useTagCatalog } from 'module/Tag';

import { parseAnalyticYaml, UnsupportedLanguageError, YamlParseError } from 'services/analyticService';

import { AnalyticForm, LanguageId } from 'types/analytic';
import { Artifact } from 'types/common';

/** Extended attributes to accompany an Analytic */
export interface AnalyticDerivedMeta {
  isSupportedLanguage: boolean;
  isNative: boolean;
  highlightProcessor: Processor;
  language?: string;
  nonSigmaCode?: string;
  analyticForm?: AnalyticForm;
}

export type AnalyticCatalog = [AnalyticLoadState, AnalyticDerivedMeta];

export const META_BASE = { isSupportedLanguage: true, isNative: false, highlightProcessor: Processor.empty() };

/** Hook to fetch an analytic and determine its derived metadata. */
export default function useAnalyticWithMeta(
  analyticID: string,
  item: Artifact,
  supplemental: SupplementalArtifact
): [AnalyticLoadState, AnalyticDerivedMeta] {
  const { data: tagOptions } = useTagCatalog();
  const res = useAnalytic(analyticID, item, supplemental);
  const sigmaCode = res.analytic?.raw;

  const meta: AnalyticDerivedMeta = useMemo(() => {
    if (!sigmaCode) return META_BASE;

    const isNative = res?.supplemental?.source_analytic_compilation_target_id !== LanguageId.Sigma;
    const [analyticForm, parseError] = parseYaml(sigmaCode, tagOptions);
    const processor = parseError ? Processor.empty() : Processor.fromAnalyticForm(analyticForm);

    if (isNative || parseError instanceof UnsupportedLanguageError) {
      const nonSigmaLanguage = Object.keys(analyticForm).find(key => key.startsWith('detection_')) || '';

      return {
        isSupportedLanguage: isNative,
        isNative,
        language: nonSigmaLanguage.split('detection_')[1],
        nonSigmaCode: analyticForm[nonSigmaLanguage],
        highlightProcessor: processor,
        analyticForm
      };
    } else return { ...META_BASE, highlightProcessor: processor, analyticForm };
  }, [sigmaCode, res?.supplemental?.source_analytic_compilation_target_id, tagOptions]);

  return useMemo(() => [res, meta], [res, meta]);
}

function parseYaml(sigmaCode: string, tagOptions: SigmaTag[]): [AnalyticForm, YamlParseError?] {
  try {
    return parseAnalyticYaml(sigmaCode, tagOptions);
  } catch (e) {
    return [{} as AnalyticForm, e];
  }
}
