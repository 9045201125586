import React, { ReactElement } from 'react';

import { layout, select } from 'd3';
import concat from 'lodash/concat';
import filter from 'lodash/filter';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import BackdropLoader from 'snap-ui/BackdropLoader';
import Icon from 'snap-ui/Icon';
import Typography from 'snap-ui/Typography';

import useCompositeMarker from 'aso/useCompositeMarker';

import Path from 'constants/paths';
import { CONDENSED_NODES, DEFAULT_ZOOM, NODE_SIZE, SEARCH_FIELDS } from 'constants/processGraph';

import useTitle from 'hooks/useTitle';

import { DetectionStatsResources } from 'module/Detection';
import useDetectionStats from 'module/Detection/useDetectionStats';
import { addMarker, deleteMarker, updateMarker } from 'module/Session/Session.service';
import { RedMarkerCreationPayload, RedMarkerUpdatePayload } from 'module/Session/Session.type';
import useSession from 'module/Session/useSession';
import useSessionHost from 'module/Session/useSessionHost';
import Fallback from 'module/Util/Fallback/Fallback';
import OrButton from 'module/Widgets/OrButton';

import {
  findNode,
  findNodeByRowId,
  findNodesWithRowIds,
  getEndTime,
  getNodeTime,
  getStartTime,
  isAChildUnfiltered,
  mapSearchData,
  nodeMatchesSomeMarkers
} from 'services/processGraphService';

import { statusGroupState } from 'storage';

import { Ident } from 'types/common';
import { RedMarker, RedMarkerExtended } from 'types/marker';
import { MinMax, NodeDataType, Zoom, ZoomOptions } from 'types/progressGraph';

import { sleep } from 'utilities/FunctionUtils';
import { formatQueryString } from 'utilities/SearchParam';

import { ProcessGraphRoot } from './ProcessGraph.style';
import Graph from './core/Graph';
import GraphHeader from './core/GraphHeader';
import GraphSidebar from './core/GraphSidebar';
import useGraphParam from './useGraphParam';
import useProcessGraph from './useProcessGraph';

const gClassName = `_${uuidv4()}`;
const svgClassName = `_${uuidv4()}`;

const ProcessGraph = (): ReactElement => {
  const [searchData, setSearchData] = React.useState([]);
  const [treeRef, setTreeRef] = React.useState(null);
  const [visible, setVisible] = React.useState(true);
  const [zoom, setZoom] = React.useState<ZoomOptions>(DEFAULT_ZOOM);
  const [collapseOnFilter, setCollapseOnFilter] = React.useState(false);
  const [totalNodes, setTotalNodes] = React.useState(0);
  const [unfilteredNodes, setUnfilteredNodes] = React.useState(0);
  const [searchNodes, setSearchNodes] = React.useState(0);

  useTitle('Process Graph | SnapAttack');
  const { push, replace, location } = useHistory();
  const param = useGraphParam();

  const [stateMinTime, setStateMinTime] = React.useState(param.minTime);
  const [stateMaxTime, setStateMaxTime] = React.useState(param.maxTime);
  const { session, statuses } = useSession(param.threatId);
  const host = useSessionHost(session.hosts);
  const {
    detection,
    status: statusDetection,
    refresh: refreshDetection
  } = useDetectionStats(DetectionStatsResources.session, param.threatId);
  const compositeMarkerLoad = useCompositeMarker(
    param.threatId,
    detection,
    host.start,
    host.machine?.name || '',
    false
  );

  const { data: rawGraph, status: statusGraph } = useProcessGraph(param.hostId);
  const [graph, setGraph] = React.useState<NodeDataType>(null);

  const [isProcessingData, setIsProcessingData] = React.useState(true);
  const statusGroup = statusGroupState(...statuses, statusDetection, statusGraph, compositeMarkerLoad.status);

  const activeHostTimeRange = React.useMemo(() => {
    const activeHost = session.hosts.find(h => h.guid === param.hostId);
    return {
      start: (activeHost && new Date(activeHost?.start).getTime()) || 0,
      end: (activeHost && new Date(activeHost?.end).getTime()) || 0
    };
  }, [session, param.hostId]);

  const selectedNode = React.useMemo(() => {
    return findNode(param.nodeId, [graph], [])[0];
  }, [graph, param.nodeId]);

  const [searchSatisfied, setSearchSatisfied] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!isEmpty(rawGraph)) {
      const payload = rawGraph;
      const endTime = getEndTime(payload);
      const startTime = getStartTime(payload);
      const _stateMaxTime = param.maxTime || endTime;
      const _stateMinTime = param.minTime || startTime;

      setStateMaxTime(_stateMaxTime);
      setStateMinTime(_stateMinTime);

      payload.process_name = param.hostname;
      payload.rootNode = true;
      payload.forceOpen = !isSearchOrFiltered();
      recursivelySetParentNode(payload);

      const nodeCount = setInitial(payload, 0, startTime, 0);
      assignInternalProperties(payload, {});
      let found: NodeDataType[] = [];
      if (param.rowId) {
        found = [...searchOnly(payload, 'row_id', [param.rowId])];
        if (!isEmpty(found)) {
          updateParams({
            searchField: SEARCH_FIELDS.ROW_ID.value,
            searchQuery: param.rowId
          });
        }
      }
      if (isEmpty(found) && param.processGuid) {
        found = [...searchOnly(payload, 'ProcessGuid' as keyof NodeDataType, [param.processGuid])];
        if (!isEmpty(found)) {
          updateParams({
            searchField: SEARCH_FIELDS.PROCESS_GUID.value,
            searchQuery: param.processGuid
          });
        }
      }
      markSelected(param.nodeId, [payload], []);
      setGraph(payload);
      setTotalNodes(nodeCount);
      setUnfilteredNodes(nodeCount);
      setInitialZoom();
      setCollapseOnFilter(!(_stateMaxTime === endTime && _stateMinTime === startTime));
      setIsProcessingData(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawGraph]);

  React.useEffect(() => {
    const data = Object.assign({}, graph);
    markSelected(param.nodeId, [data], []);
    setGraph(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param.nodeId]);

  React.useEffect(() => {
    const data = Object.assign({}, graph);
    processSearch(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [compositeMarkerLoad.markers, param.searchField, param.searchQuery]);

  React.useEffect(() => {
    const tempData = Object.assign({}, graph);
    updateParams({
      minTime: stateMinTime,
      maxTime: stateMaxTime
    });
    if (collapseOnFilter) {
      resetCollapse([tempData]);
    }
    const nodeCount = update(tempData, 0);
    // make sure root node is never collapsed when filtering
    tempData._collapsed = false;
    setGraph(tempData);
    setUnfilteredNodes(nodeCount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateMinTime, stateMaxTime, collapseOnFilter]);

  function assignInternalProperties(data, parent?): void {
    data._children = data.children;
    data.parent = parent;
    forEach(data._children, child => assignInternalProperties(child, data));
  }

  function processSearch(data: NodeDataType): void {
    setSearchData(mapSearchData(param, data, compositeMarkerLoad.markers.all));

    let tempData = Object.assign({}, data);
    if (!isEmpty(param.searchQuery) && !isEmpty(param.searchField) && data && !searchSatisfied) {
      recursivelySetParentNode(tempData);
      setGraph(tempData);
      const rowIds = [];
      if (param.searchField === SEARCH_FIELDS.ANALYTIC_HITS.value) {
        // This is likely broken. id's across different tables are bound to overlap.
        rowIds.push(compositeMarkerLoad.markers.red.filter(m => m.id.toString() === param.searchQuery).map(m => m.id));
        rowIds.push(
          compositeMarkerLoad.markers.blue.filter(m => m.event.id.toString() === param.searchQuery).map(m => m.event.id)
        );
      }
      setIsProcessingData(true);
      tempData = Object.assign({}, tempData);
      const found = searchTree(tempData, rowIds);
      setSearchNodes(size(found));
      setGraph(tempData);
      sleep(500).then(() => {
        if (size(found) === 1) {
          handleZoom(Zoom.FOCUS, found[0]);
          updateParams({ nodeId: found[0]._id });
          setSearchSatisfied(true);
        } else {
          handleZoom(Zoom.FIT);
        }
        setIsProcessingData(false);
      });
    } else if (isEmpty(param.searchQuery) && data) {
      setSearchSatisfied(false);
      resetSearch([tempData]);
      setGraph(tempData);
    }
  }

  function resetSearch(nodeSet: NodeDataType[]): void {
    nodeSet.forEach(node => {
      node['found'] = false;
      node['found_red'] = false;
      node['child_found'] = false;
      node['child_found_red'] = false;
      if (node._children) {
        resetSearch(node._children);
      }
    });
  }

  function getClassName(): string {
    if (!isEmpty(param.searchField) && !isEmpty(param.searchQuery)) return 'found';
    else return null;
  }

  function isFoundDisplayed(data): boolean {
    if (!data) return false;
    if (data.found || data.found_red) {
      return true;
    }
    let result = false;
    if (data._children && !data._collapsed) {
      data._children.forEach(child => {
        if (!result) {
          result = isFoundDisplayed(child);
        }
      });
    }
    return result;
  }

  function setInitial(data, depth, startTime, nodeCount): number {
    data['_id'] = data.id;
    data['depth'] = depth;
    // set start offset
    data['start_offset'] = (getNodeTime(data) || startTime) - startTime;
    nodeCount++;
    if (data.children) {
      data.children.forEach(child => {
        if (child._collapsed === undefined) {
          child._collapsed = true;
          child.forceOpen = false;
          child.condensed = true;
        }
        nodeCount = setInitial(child, depth + 1, startTime, nodeCount);
      });
    }
    return nodeCount;
  }

  function recursivelySetParentNode(node: NodeDataType, parent?: NodeDataType): void {
    if (!node) return;
    node.parent = parent;
    if (node.children) {
      node.children.forEach(child => {
        recursivelySetParentNode(child, node);
      });
    }
  }

  function getMaxMin(node, minMax: MinMax, useClassName?, className?): MinMax {
    if (!node) return minMax;
    if (node._children && !node._collapsed) {
      node._children.forEach(child => {
        minMax = getMaxMin(child, minMax, useClassName, className);
      });
    }
    if (!useClassName || (className && node[className])) {
      if (!minMax.xMax || node.x > minMax.xMax) {
        minMax.xMax = node.x;
      }
      if (!minMax.xMin || node.x < minMax.xMin) {
        minMax.xMin = node.x;
      }
      if (!minMax.yMax || node.y > minMax.yMax) {
        minMax.yMax = node.y;
      }
      if (!minMax.yMin || node.y < minMax.yMin) {
        minMax.yMin = node.y;
      }
    }
    return minMax;
  }

  function isSearchOrFiltered(): boolean {
    return (!isEmpty(param.searchQuery) && !isEmpty(param.searchField)) || unfilteredNodes !== totalNodes;
  }

  function generateTree() {
    const tree = layout
      .tree()
      .nodeSize([NODE_SIZE.y, NODE_SIZE.x])
      .separation((a, b) => (a.parent.id === b.parent.id ? 0.25 : 0.5))
      .children(d => {
        if (d._collapsed) return null;
        if (param.searchQuery && param.searchField && !d.forceOpen) {
          // if the node is not found or parent found
          const shownNodes = filter(
            d._children,
            child =>
              child.found ||
              child.found_red ||
              child.child_found ||
              child.child_found_red ||
              child.child_selected ||
              child.child_selected_red ||
              child.selected ||
              child.selected_red
          );
          if (size(shownNodes) > 0 && size(d._children) - size(shownNodes) > 0) {
            shownNodes.push({
              id: uuidv4(),
              _id: `${d._id}_condense`,
              process_name: `Expand ${size(d._children) - size(shownNodes)} nodes`,
              condenseNode: true
            });
          }
          return shownNodes;
        }
        if (unfilteredNodes !== totalNodes && !d.forceOpen) {
          const shownNodes = filter(
            d._children,
            child =>
              !child.filterCollapse ||
              isAChildUnfiltered(child) ||
              child.child_selected ||
              child.child_selected_red ||
              child.selected ||
              child.selected_red
          );
          if (size(shownNodes) > 0 && size(d._children) - size(shownNodes) > 0) {
            shownNodes.push({
              id: uuidv4(),
              _id: `${d._id}_condense`,
              process_name: `Expand ${size(d._children) - size(shownNodes)} nodes`,
              condenseNode: true
            });
          }
          return shownNodes;
        }
        if (d.condensed && size(d._children) > CONDENSED_NODES && d.depth > 0) {
          // show three of the first nodes and then a node that says how many more there are
          const condensed_children = [];
          for (let i = 0; i < CONDENSED_NODES; i++) {
            condensed_children[i] = d._children[i];
          }
          // add the new node that states the number of additional children
          condensed_children[CONDENSED_NODES] = {
            id: uuidv4(),
            _id: `${d._id}_condense`,
            process_name: `Expand ${size(d._children) - CONDENSED_NODES} nodes`,
            condenseNode: true
          };
          return condensed_children;
        } else if (!d.condensed && size(d._children) > CONDENSED_NODES && d.depth > 0) {
          const expandedChildren = concat([], d._children);
          expandedChildren.push({
            id: uuidv4(),
            _id: `${d._id}_condense`,
            process_name: `Condense Nodes`,
            condenseNode: true
          });
          return expandedChildren;
        }
        return d._children;
      });
    if (graph) {
      const nodes = tree.nodes(graph);
      const links = tree.links(nodes);
      return { nodes, links };
    }
    return { nodes: [], linkes: [] };
  }

  function handleZoom(zoomDirection: Zoom, node?): void {
    const dimensions = treeRef ? treeRef.getBoundingClientRect() : { width: 1300, height: 700 };
    const newZoom = Object.assign({}, zoom);
    const tempNode = node || findNode(param.nodeId, [graph], [])[0];
    newZoom.zoomDirection = zoomDirection;
    let x, y;
    if (Zoom.FOCUS === zoomDirection) {
      newZoom.zoom = 0.45;
      x = -get(tempNode, 'x') || 0;
      y = -get(tempNode, 'y') || 0;
    } else if (Zoom.SINGLE_FIT === zoomDirection) {
      x = -get(tempNode, 'x') || 0;
      y = -get(tempNode, 'y') || 0;
      const minMax = getMaxMin(
        graph,
        {
          yMin: 0,
          yMax: get(tempNode, 'y'),
          xMin: 0,
          xMax: get(tempNode, 'x')
        },
        isFoundDisplayed(graph),
        getClassName()
      );
      newZoom.zoom = Math.min(
        dimensions.width / (Math.abs(minMax.yMin) + Math.abs(minMax.yMax) + dimensions.width),
        dimensions.height / (Math.abs(minMax.xMin) + Math.abs(minMax.xMax) + dimensions.height)
      );
    } else {
      const minMax = getMaxMin(
        graph,
        {
          yMin: null,
          yMax: null,
          xMin: null,
          xMax: null
        },
        isFoundDisplayed(graph),
        getClassName()
      );
      x = (minMax.xMax + minMax.xMin) / -2;
      y = (minMax.yMax + minMax.yMin) / -2;
      // provide padding for the dimensions so fitting isn't up against the edge
      const width = dimensions.width - 50;
      const height = dimensions.height - 50;
      newZoom.zoom = Math.min(
        width / (Math.abs(minMax.yMin) + Math.abs(minMax.yMax) + width),
        height / (Math.abs(minMax.xMin) + Math.abs(minMax.xMax) + height)
      );
    }
    x = x * newZoom.zoom + dimensions.width / 4.5;
    y = y * newZoom.zoom + dimensions.height / 1.25;
    newZoom.translate = { y: x, x: y };
    const g = select(`.${gClassName}`);
    g.attr('transform', `translate(${[newZoom.translate.x, newZoom.translate.y]}) scale(${newZoom.zoom})`);
    setZoom(newZoom);
  }

  function setInitialZoom(): void {
    const dimensions = treeRef ? treeRef.getBoundingClientRect() : { width: 1300, height: 700 };
    const newZoom = Object.assign({}, zoom);
    newZoom.translate = { y: dimensions.height / 2, x: dimensions.width / 5 };
    const g = select(`.${gClassName}`);
    g.attr('transform', `translate(${[dimensions.width / 5, dimensions.height / 2]}) scale(${newZoom.zoom})`);
    setZoom(newZoom);
  }

  function updateZoom(scale: number, translateX: number, translateY: number) {
    const newZoom = Object.assign({}, zoom);
    newZoom.zoom = scale;
    newZoom.translate = {
      x: translateX,
      y: translateY
    };
    setZoom(newZoom);
  }

  function update(d: NodeDataType, nodeCount: number): number {
    if (!d) return;
    if (d._children)
      d._children.forEach(child => {
        nodeCount = update(child, nodeCount);
      });

    let timeFilter = false;

    if (d.UtcTime) {
      timeFilter = !(getNodeTime(d) >= stateMinTime && getNodeTime(d) <= stateMaxTime);
    } else {
      timeFilter = stateMinTime > getStartTime(graph) || stateMaxTime < getEndTime(graph);
    }

    d.filtered = timeFilter;
    if (!d.filtered) {
      nodeCount++;
    }
    if (d.filtered && collapseOnFilter) {
      d.filterCollapse = true;
    }
    if (!d.filtered && collapseOnFilter) {
      d.filterCollapse = false;
      let parent = d.parent;
      while (parent) {
        parent._collapsed = false;
        parent.condensed = false;
        parent = parent.parent;
      }
    }
    return nodeCount;
  }

  function searchOnly<K extends keyof NodeDataType>(
    d: NodeDataType,
    field: K,
    values: NodeDataType[K][]
  ): NodeDataType[] {
    const found: NodeDataType[] = [];
    if (!d) return found;
    for (const child of d.children || []) {
      found.push(...searchOnly(child, field, values));
    }

    const searchFieldValue = get(d, field);
    if (includes(values, searchFieldValue)) found.push(d);
    return found;
  }

  function searchTree(d: NodeDataType, rowIds?: number[], searchGuid?: boolean): NodeDataType[] {
    let found = [];
    if (!d) return found;
    if (!isEmpty(d._children)) {
      d._children.forEach(child => {
        found = concat(found, searchTree(child, rowIds, searchGuid));
      });
    }

    let searchFieldValue;
    if (param.searchField && param.searchQuery) {
      if (
        param.searchField === SEARCH_FIELDS.ATTACK_NODE.value ||
        param.searchField === SEARCH_FIELDS.ANALYTIC_HITS.value
      ) {
        searchFieldValue = d.row_id;
      } else {
        searchFieldValue = d[param.searchField];
      }
    } else {
      if (searchGuid) {
        searchFieldValue = d['guid'];
      } else {
        searchFieldValue = d.row_id;
      }
    }
    if ((searchFieldValue && searchFieldValue === param.searchQuery) || includes(rowIds, searchFieldValue)) {
      found = concat(found, d);
      // TODO we never drop into this block. Research what is failing
      const redStar = nodeMatchesSomeMarkers(d, compositeMarkerLoad.markers.red);
      if (redStar) {
        d.found_red = true;
      } else {
        d.found = true;
      }
      d._collapsed = false;
      let parent = d.parent;
      while (parent) {
        if (redStar) {
          parent['child_found_red'] = true;
        } else {
          parent['child_found'] = true;
        }
        parent._collapsed = false;
        parent.condensed = false;
        parent = parent.parent;
      }
    } else {
      d.found = false;
      d.found_red = false;
    }
    return found;
  }

  function resetSelected(nodeSet: NodeDataType[]): void {
    nodeSet.forEach(node => {
      node.selected = false;
      node.selected_red = false;
      node['child_selected'] = false;
      node['child_selected_red'] = false;
      if (node.children) {
        resetSelected(node.children);
      }
    });
  }

  function resetFound(nodeSet): void {
    nodeSet.forEach(node => {
      node.found = false;
      node.found_red = false;
      if (node.children) {
        resetFound(node.children);
      }
    });
  }

  function resetCollapse(nodeSet): void {
    nodeSet.forEach(node => {
      node._collapsed = true;
      node.condensed = true;
      node.filterCollapse = false;
      if (node.children) {
        resetCollapse(node.children);
      }
    });
  }

  function markSelected(nodeId: string, nodeSet: NodeDataType[], hits: NodeDataType[]): NodeDataType[] {
    hits = hits.concat(nodeSet.filter(node => node._id === nodeId));
    nodeSet.forEach(node => {
      if (node.children && node.children.length > 0) {
        hits = markSelected(nodeId, node.children, hits);
      }
    });

    if (hits.length > 0) {
      hits.forEach((hit, index) => {
        let redStar; // = hasMarker(hit, markers, MARKER_TYPE.RED);
        if (redStar) {
          hit.selected_red = true;
        } else {
          hit['selected'] = true;
        }
        let parent = hit.parent;
        while (parent) {
          parent._collapsed = false;
          if (index >= CONDENSED_NODES) {
            parent.condensed = false;
          }
          if (redStar) {
            parent['child_selected_red'] = true;
          } else {
            parent['child_selected'] = true;
          }
          parent = parent.parent;
        }
      });
    }

    return hits;
  }

  function handleNodeClick(node: NodeDataType): void {
    const data = Object.assign({}, graph);
    if (node.condenseNode) {
      const foundNodes = findNode(node._id, [data], []);
      if (foundNodes[0]) {
        let parent: NodeDataType;
        if (foundNodes[0].parent.process_name === data.process_name) {
          parent = data;
        } else {
          parent = foundNodes[0].parent;
        }
        if (parent.condensed || !parent.forceOpen) {
          parent.condensed = false;
          parent.forceOpen = true;
        } else {
          parent.condensed = true;
          parent.forceOpen = false;
        }
        setGraph(data);
      }
    } else {
      if (param.nodeId !== node._id) {
        resetSelected([data]);
        setGraph(data);
        updateParams({ nodeId: node._id });
      }
    }
  }

  function createAnalytic(row_id, hostname): void {
    push({
      pathname: Path.IDE,
      search: formatQueryString({
        threat: param.threatId,
        row_id: row_id,
        hostname: hostname
      })
    });
  }

  function updateSearchQueryParam(searchQuery: string): void {
    setSearchSatisfied(false);
    updateParams({
      searchField: param.searchField,
      searchQuery
    });
  }

  function updateSearchFieldParam(searchField: string): void {
    setSearchSatisfied(false);
    updateParams({
      searchField,
      searchQuery: null
    });
  }

  function setTime(min: number, max: number): void {
    setStateMinTime(min);
    setStateMaxTime(max);
  }

  function setTimeAndAutoExpand(min: number, max: number): void {
    setTime(min, max);
    setCollapseOnFilter(true);
  }

  function updateParams(replacementParam: Record<string, unknown>): void {
    replace({
      pathname: location.pathname,
      search: formatQueryString({
        ...param,
        ...replacementParam
      })
    });
  }

  async function handleSaveMarker(marker: RedMarkerCreationPayload, selectedNode: NodeDataType) {
    const payload: RedMarkerCreationPayload = {
      ...marker,
      event_row: selectedNode?.row_id
    };

    await addMarker(param.threatId, param.hostId, payload, compositeMarkerLoad);
    compositeMarkerLoad.refresh();
    refreshDetection();
  }

  async function handleDeleteMarker(markerId: Ident) {
    await deleteMarker(param.threatId, param.hostId, markerId, compositeMarkerLoad);
    compositeMarkerLoad.refresh();
    refreshDetection();
  }

  async function handleUpdateMarker(marker: RedMarkerExtended, payload: RedMarkerUpdatePayload) {
    await updateMarker(param.threatId, param.hostId, marker.id, payload, compositeMarkerLoad);
    compositeMarkerLoad.refresh();
    refreshDetection();
  }

  function collapseNode(node: NodeDataType): void {
    node._collapsed = true;
    node.condensed = true;
    node.forceOpen = false;
    if (node._children && node._children.length > 0) {
      node._children.forEach(child => {
        collapseNode(child);
      });
    }
  }

  function handleNodeCollapseExpand(node): void {
    const data = Object.assign({}, graph);
    const foundNodes = findNode(node._id, [data], []);

    if (foundNodes[0]) {
      const targetNode = foundNodes[0];
      if (targetNode._collapsed) {
        targetNode._collapsed = false;
        if ((param.searchQuery && param.searchField) || totalNodes !== size(foundNodes)) {
          targetNode.forceOpen = true;
        }
      } else {
        collapseNode(targetNode);
      }
      setGraph(data);
    }
  }
  function selectNodeByAttack(attack: RedMarker): void {
    const data = Object.assign({}, graph);
    const attackNode = findNodeByRowId(attack.event?.row_id, [data]);
    if (attackNode) {
      setIsProcessingData(true);
      let parent = attackNode.parent;
      while (parent) {
        parent._collapsed = false;
        parent.condensed = false;
        parent = parent.parent;
      }
      setGraph(data);
      sleep(500).then(() => {
        const foundNode = findNodeByRowId(attack.event?.row_id, [data]);
        handleZoom(Zoom.FOCUS, foundNode);
        setIsProcessingData(false);
      });
    }
  }
  function selectNodeByAnalytic(): void {
    const data = Object.assign({}, graph);
    resetFound([data]);
    // const rowIds = filter(markers, marker => marker.analytic_tag === analytic.analytic_tag).map(
    //   marker => `${marker.event_id}`
    // );
    let rowIds;
    const analyticNodes = findNodesWithRowIds(rowIds, data);
    if (size(analyticNodes) > 0) {
      setIsProcessingData(true);
      forEach(analyticNodes, analyticNode => {
        analyticNode.found = true;
        let parent = analyticNode.parent;
        while (parent) {
          parent._collapsed = false;
          parent.condensed = false;
          parent = parent.parent;
        }
      });
      setGraph(data);
      sleep(350).then(() => {
        const foundNode = findNodeByRowId(analyticNodes[0].row_id, [data]);
        handleZoom(Zoom.SINGLE_FIT, foundNode);
        setIsProcessingData(false);
      });
    }
  }

  const { nodes, links } = generateTree();

  if (statusGroup.hasError)
    return (
      <Fallback
        message='We encountered an issue opening this Process Graph. If this issue persists, please report it using the button below.'
        action={
          <OrButton
            onClick={() => {
              push(`/session/${param.threatId}`);
            }}
            color='secondary'
          >
            <Icon.Session />
            <span>Back to Threat</span>
          </OrButton>
        }
      />
    );

  return (
    <ProcessGraphRoot>
      <BackdropLoader
        title={
          statusGroup.hasPending ? `Loading tasks ${statusGroup.done + 1} of ${statusGroup.total}...` : 'Updating...'
        }
        open={isProcessingData || statusGroup.hasPending}
        contained
        fixed
      />
      <a
        href={`${Path.Threat}/${param.threatId}`}
        target='_blank'
        rel='noopener noreferrer'
        className='graph-session-name'
      >
        <Typography variant='h3'>{session.name}</Typography>
      </a>
      <GraphHeader
        searchQuery={param.searchQuery}
        setSearchQuery={updateSearchQueryParam}
        searchField={param.searchField}
        setSearchField={updateSearchFieldParam}
        searchData={searchData}
        setZoom={handleZoom}
        minTime={stateMinTime}
        maxTime={stateMaxTime}
        setTime={setTime}
        sessionStart={activeHostTimeRange.start}
        sessionEnd={activeHostTimeRange.end}
        sidebarOpened={visible}
        collapseNodes={collapseOnFilter}
        setCollapseNodes={setCollapseOnFilter}
        totalNodes={totalNodes}
        unfilteredNodes={unfilteredNodes}
        marker={compositeMarkerLoad.markers.all}
        searchCount={searchNodes}
        isLoading={isProcessingData || statusGroup.hasPending}
      />
      {graph !== null && (
        <Graph
          data={graph[0]}
          zoom={zoom.zoom || 0.1}
          translate={zoom.translate}
          center={zoom.translate}
          separation={{ siblings: 0.25, nonSiblings: 0.5 }}
          nodeSize={NODE_SIZE}
          scaleExtent={{ min: 0, max: 1 }}
          styles={{ links: {}, nodes: {} }}
          setContainerRef={setTreeRef}
          updateZoom={updateZoom}
          gClassName={gClassName}
          svgClassName={svgClassName}
          nodeClick={handleNodeClick}
          expandClick={handleNodeCollapseExpand}
          nodes={nodes}
          links={links}
          composite={compositeMarkerLoad.markers}
          detection={detection}
        />
      )}
      <GraphSidebar
        selectedNode={selectedNode}
        createAnalytic={createAnalytic}
        onMarkerSave={handleSaveMarker}
        visible={visible}
        setVisible={setVisible}
        url={window.location.href}
        hostname={param.hostname}
        session={session}
        onMarkerDelete={handleDeleteMarker}
        onMarkerUpdate={handleUpdateMarker}
        composite={compositeMarkerLoad.markers}
        detection={detection}
        setTime={setTimeAndAutoExpand}
        onAttackClick={selectNodeByAttack}
        onAnalyticClick={selectNodeByAnalytic}
      />
    </ProcessGraphRoot>
  );
};

export default ProcessGraph;
