import React from 'react';

import { faStar } from '@fortawesome/pro-solid-svg-icons';

import Icon from 'snap-ui/Icon';
import { theme } from 'snap-ui/theme';
import { styled } from 'snap-ui/util';

import UndetectedThreatIcon from 'module/Icons/UndetectedThreatIcon';

import { RedMarkerModal } from './RedMarkerModal';

type Provider = { children: React.ReactNode };
type Dialog = Omit<RedMarkerModal, 'onClose' | 'isOpen'>;
type Button = Pick<RedMarkerModal, 'newMarker' | 'offset' | 'startTime' | 'marker'>;

type RedMarkerContextValue = {
  activeEditor: Button;
  setActiveEditor(props: Button): void;
};

const MarkerButton = styled('a')`
  cursor: pointer;
`;

const RedMarkerContext = React.createContext<RedMarkerContextValue>(null);
RedMarkerContext.displayName = 'RedMarkerContext';

export function RedMarkerInterface({ children }: Provider): JSX.Element {
  const [activeEditor, setActiveEditor] = React.useState<Button>();

  return <RedMarkerContext.Provider value={{ activeEditor, setActiveEditor }}>{children}</RedMarkerContext.Provider>;
}

function useRedMarkerContext(): RedMarkerContextValue {
  const context = React.useContext(RedMarkerContext);
  if (!context) {
    throw new Error('RedMarker components must be used within a RedMarkerContext');
  }
  return context;
}

export function RedMarkerButton(props: Button) {
  const { marker, newMarker } = props;
  const { setActiveEditor } = useRedMarkerContext();

  function handleClick(): void {
    setActiveEditor(props);
  }

  return (
    <MarkerButton onClick={handleClick}>
      {newMarker ? (
        <Icon icon={faStar} color={theme.palette.common.white} />
      ) : marker?.lonely ? (
        <UndetectedThreatIcon />
      ) : (
        <Icon icon={faStar} color='error' />
      )}
    </MarkerButton>
  );
}

export function RedMarkerDialog(props: Dialog) {
  const { activeEditor, setActiveEditor } = useRedMarkerContext();

  function handleClose() {
    setActiveEditor(null);
  }

  return <RedMarkerModal isOpen={!!activeEditor} onClose={handleClose} {...props} {...activeEditor} />;
}
