import { Artifact, ArtifactScore } from 'types/common';

export const ARTIFACT_SCORE_OPTIONS = Object.entries(ArtifactScore).map(([key, value]) => ({
  key,
  content: value,
  value
}));

export const TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss.SSS';

export const MULTIPART_REQUEST_CONFIG = { headers: { 'Content-Type': 'multipart/form-data' } };

export const ArtifactShell: Artifact = {
  short_id: null,
  organization_id: 0,
  visibility: null,
  modified: null,
  creation: null,
  created_by_id: 0,
  modified_by_id: 0,
  owner_id: 0,
  small_image: null,
  large_image: null,
  logsource: null,
  preview: false,
  linked_actors: [],
  linked_attacks: [],
  linked_tags: [],
  guid: null,
  name: null,
  description: null,
  tags: [],
  actor_names: [],
  attack_names: [],
  software_names: [],
  vulnerability_names: []
};
