import React from 'react';

import { useIntegrationCatalog } from 'provider';

import { Guid } from 'types/common';

import { Hits, SocDashboardStats } from '../SocDashboard.type';
import {
  Integration,
  IntegrationDemo,
  IntegrationExample,
  PlaceHolderIntegration,
  grade
} from './IntegrationBoard.widgets';

type Props = {
  integrations?: SocDashboardStats;
  hitsByIntegration?: Record<Guid, { hits: Hits['integration_hits']; total: 0 }>;
  demoMode?: boolean;
  handleDeleteIntegration: (id: string) => void;
};

function IntegrationsBoard({ hitsByIntegration = {}, integrations = {}, demoMode, handleDeleteIntegration }: Props) {
  const { integrations: integrationCatalog } = useIntegrationCatalog();

  const SocIDs = Object.keys(integrations);
  const SocData = Object.values(integrations);
  const integrationsArr = SocData.slice(0, -1);

  if (demoMode) {
    return <IntegrationDemo />;
  }

  if (integrationCatalog.preferred_org_aware.length === 0) {
    return <PlaceHolderIntegration />;
  }

  if (integrationsArr.length === 0) {
    return <IntegrationExample />;
  }

  return (
    <>
      {integrationsArr.map((integration, index) => {
        const id = SocIDs[index];
        const integrationItem = integrationCatalog.preferred_org_aware.find(i => Number(id) === i.id);
        if (!integrationItem) return null;

        const { guid, name, deployment_targets } = integrationItem;
        const languageIds = deployment_targets.map(l => l.id);
        const lastIndex = integration.length - 1;
        const {
          deployments,
          untesteds,
          unvalidations,
          validation_gaps,
          validations,
          confidence_highest,
          confidence_high,
          confidence_medium,
          confidence_low,
          confidence_lowest,
          score
        } = integration[lastIndex];
        const hits = hitsByIntegration[integrationItem.guid];
        const confidenceData = [
          {
            name: 'Highest',
            value: confidence_highest
          },
          {
            name: 'High',
            value: confidence_high
          },
          {
            name: 'Medium',
            value: confidence_medium
          },
          {
            name: 'Low',
            value: confidence_low
          },
          {
            name: 'Lowest',
            value: confidence_lowest
          }
        ];
        const validatedDetections = [
          {
            name: 'Validated',
            value: validations
          },
          {
            name: 'Unvalidated',
            value: unvalidations + validation_gaps
          },
          {
            name: 'Untested',
            value: untesteds
          }
        ];
        const detectionData = integration.map(i => {
          return {
            value: i.deployments,
            time: i.time
          };
        });

        return (
          <Integration
            handleDeleteIntegration={handleDeleteIntegration}
            detectionData={detectionData}
            detectionHits={hits}
            detections={deployments}
            letterGrade={grade(score)}
            title={name}
            guid={guid}
            languageID={languageIds}
            validatedDetections={validatedDetections}
            key={index}
            index={index}
            confidenceData={confidenceData}
            id={id}
          />
        );
      })}
    </>
  );
}

export default IntegrationsBoard;
