import React from 'react';

import { CancelToken } from 'apis/snapattack';

import { getSearchPage } from 'module/Feed/Feed.service';
import { Feed } from 'module/Feed/Feed.type';

import { Status, useAsync } from 'storage';

import { ArtifactType } from 'types/common';
import { Ops } from 'types/filter';

import { postAttackScriptSessions } from '../AttackScript.api';

export type AssociatedSessionInterface = {
  associatedSessions: Feed;
  fetchAssociatedSessions: () => void;
  associatedSessionsStatus: Status;
  saveSessions: (scriptGuid: string, newSessionGuids: string[]) => Promise<void>;
  isSaving: boolean;
};

export default function useAssociatedSessions(attackScriptId: number): AssociatedSessionInterface {
  const {
    data: associatedSessions,
    run: associatedSessionsRun,
    status
  } = useAsync<Feed>({ items: [], page: 1, size: 50, total: 0 });
  const { task: saveTask, status: associatedSessionsStatus } = useAsync();

  const fetchAssociatedSessions = React.useCallback(() => {
    if (!attackScriptId) return;
    const cancelToken = CancelToken.source();
    associatedSessionsRun(
      getSearchPage(
        ArtifactType.Session,
        1,
        50,
        { field: 'session.bas_scripts.id', op: Ops.equals, value: attackScriptId },
        '',
        cancelToken
      )
    );
    return () => cancelToken?.cancel();
  }, [associatedSessionsRun, attackScriptId]);

  React.useEffect(() => {
    fetchAssociatedSessions();
  }, [fetchAssociatedSessions]);

  const saveSessions = React.useCallback(
    async (scriptGuid: string, newSessionGuids: string[]) => {
      if (associatedSessions?.items?.length > 0) {
        // Need to remove existing associations first. There is no way to update associations.
        await saveTask(
          postAttackScriptSessions(
            scriptGuid,
            'delete',
            associatedSessions.items.map(session => session.guid)
          )
        );
      }
      await saveTask(postAttackScriptSessions(scriptGuid, 'add', newSessionGuids));
    },
    [saveTask, associatedSessions.items]
  );

  return {
    associatedSessions,
    fetchAssociatedSessions,
    associatedSessionsStatus: status,
    isSaving: associatedSessionsStatus === Status.pending,
    saveSessions
  };
}
