import { MIN_PERCENTAGE } from 'snap-ui/Charts/SpecifiedDomainRadarChart';

import { Integration } from 'module/Integration/Integration.type';

import { getPoints, ParsableDate } from 'utilities/TimeUtils';

import { Hits, RadarData, SocDashboardStats } from './SocDashboard.type';

export const getColorKey = (data: SocDashboardStats, integrations: Integration[]) => {
  return Object.keys(data ?? {})
    .map(integrationId => {
      const integration = integrations?.find(integration => integration.id.toString() === integrationId);

      if (integration) {
        return integration.name;
      }
    })
    .filter(name => {
      return !!name;
    })
    .reduce((colorKey, name, index) => {
      return {
        ...colorKey,
        [name]: index
      };
    }, {});
};

export const getAttackCoverageData = (data: SocDashboardStats, integrations: Integration[]): RadarData[] => {
  const attackCoverageData = {};
  Object.entries(data ?? {}).forEach(([integrationId, integrationData]) => {
    const integration = integrations.find(integration => integration?.id?.toString() === integrationId);
    if (integration?.name) {
      // get last time slice of coverage
      const coverage = integrationData[integrationData.length - 1]?.coverage;
      coverage.forEach(item => {
        const { tag_name, coverage, children } = item;
        let percentage = Math.floor((coverage / children) * 100);
        percentage = percentage < 1 ? MIN_PERCENTAGE : percentage;

        if (!attackCoverageData[tag_name]) {
          attackCoverageData[tag_name] = {
            name: tag_name,
            values: {
              [integration.name]: percentage
            }
          };
        } else {
          attackCoverageData[tag_name].values[integration.name] = percentage;
        }
      });
    }
  });

  return Object.values(attackCoverageData);
};

/**
 * combines multiple data sets by date to give a single data set
 */
export function combineHitsCharts(integrationHits?: Hits['integration_hits'][]): Hits['integration_hits'] {
  if (!integrationHits) return [];

  const hitsByDay: Record<string, number> = {};
  integrationHits.forEach(hits =>
    hits?.forEach(hit => {
      if (hit.day in hitsByDay) {
        hitsByDay[hit.day] += hit.hits;
      } else {
        hitsByDay[hit.day] = hit.hits;
      }
    })
  );
  return Object.entries(hitsByDay).map(([day, hits]) => ({ day, hits }));
}

/**
 * fills in missing dates with 0s
 */
export function fillHitsChart(
  start: ParsableDate,
  stop: ParsableDate,
  hits?: Hits['integration_hits']
): Hits['integration_hits'] {
  const dates = getPoints(start, stop);
  return combineHitsCharts([dates.map(date => ({ hits: 0, day: date.format('YYYY-MM-DD') })), hits]);
}
