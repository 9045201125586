import React from 'react';

import { Status, useAsync } from 'storage';

import { annotateIndicatorsWithWarnings } from '../IOC.api';
import { IOC } from '../IOC.type';

type AnnotateIOCInterface = {
  annotatedIOCs: IOC[];
  annotateIOCsWithWarnings: (iocs: IOC[], saveData?: boolean) => Promise<IOC[]>;
  status: Status;
};

export default function useAnnotateIOCs(): AnnotateIOCInterface {
  const { data: annotatedIOCs, task, status, setData } = useAsync<IOC[]>([]);

  const annotateIOCsWithWarnings = React.useCallback(
    (iocs: IOC[], saveData = true) =>
      task(
        annotateIndicatorsWithWarnings(iocs).then(annotatedIOCs => {
          if (saveData) setData(annotatedIOCs);

          return annotatedIOCs;
        })
      ),
    [setData, task]
  );

  return { annotatedIOCs, annotateIOCsWithWarnings, status };
}
