import React, { MutableRefObject } from 'react';

import { faCircle, faCrosshairs, faCube, faFileAlt, faSitemap, faStar } from '@fortawesome/pro-solid-svg-icons';
import { CompositeMarkerLoadState } from 'aso';
import { default as classnames } from 'classnames';
import { Link, useLocation } from 'react-router-dom';

import { Alert, AlertTitle } from 'snap-ui/Alert';
import { Certified } from 'snap-ui/Cert';
import { DataGrid, GridColDef, useGridApiRef } from 'snap-ui/DataGrid';
import { default as Icon } from 'snap-ui/Icon';
import { List, ListItem } from 'snap-ui/List';
import Placeholder from 'snap-ui/Placeholder';
import { TablePlaceholder } from 'snap-ui/Table';
import Tooltip, { TooltipConstrained } from 'snap-ui/Tooltip';
import { styled, useTheme } from 'snap-ui/util';

import Route, { Path } from 'constants/paths';

import { AnalyticPreviewProvider, useAnalyticPreview } from 'module/Analytic/core/AnalyticPreviewProvider';
import UndetectedThreatIcon from 'module/Icons/UndetectedThreatIcon';
import UnvalidatedAnalyticIcon from 'module/Icons/UnvalidatedAnalyticIcon';
import { MarkerColor } from 'module/Session/Session.const';
import { addMarker, deleteMarker, updateMarker } from 'module/Session/Session.service';
import { Host, RedMarkerCreationPayload, RedMarkerUpdatePayload, Session } from 'module/Session/Session.type';
import Badge from 'module/Widgets/Badge';
import ConfidenceScoreBadge from 'module/Widgets/ConfidenceScoreBadge';
import { RedMarkerButton, RedMarkerDialog, RedMarkerInterface } from 'module/Widgets/RedMarkerModal';

import { useAuth } from 'provider';

import { checkContentPermission, checkTaskPermission } from 'services/authService';
import { isBlueMarker } from 'services/filterService/filterService';

import { Status } from 'storage';

import { AnalyticRecommendation } from 'types/analytic';
import { ContentPermission, FunctionalPermission } from 'types/auth';
import { ArtifactScore } from 'types/common';
import { BlueMarker, BlueMarkerExtended, MarkerExtended, RedMarker, RedMarkerExtended } from 'types/marker';

import { getPreferredOrgScore, getScoreDetails } from 'utilities/ArtifactUtils';
import { getLocation } from 'utilities/RouteGenUtils';
import { formatQueryString, parseAnyQueryString } from 'utilities/SearchParam';
import { convertSecondsToMMss, getDiff } from 'utilities/TimeUtils';

import HitLog from './HitLog';

const TimelineRoot = styled('div', { label: 'Timeline' })`
  height: 700px;
  min-width: 640px;

  .MuiDataGrid-columnHeaders {
    background-color: ${p => p.theme.palette.primary.dark};
    background: ${p => p.theme.palette.surface.gradient};
  }

  .hollow-icon {
    color: ${p => p.theme.palette.surface.odd};
  }

  .timeline-actions {
    display: flex;
    justify-content: left;
    gap: ${p => p.theme.spacing(4)};
  }

  .Timeline-row {
    border-top: 1px solid ${p => p.theme.palette.grey[700]};
    background: ${p => p.theme.palette.surface.odd};

    &.Timeline-row-marker-precision {
      border: 1px dashed ${p => p.theme.palette.common.white};
      border-left: 5px double ${p => p.theme.palette.orange.main};
    }

    &.Timeline-row-recommended-detection {
      background-color: ${p => p.theme.palette.surface.hover};
      :hover {
        background-color: ${p => p.theme.palette.surface.even};
      }
    }
  }

  .no-break {
    width: 100%;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .ConfidenceScoreBadge {
    min-width: 104px;
    font-size: 0.75rem;
  }
`;

const AnalyticPreviewContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${p => p.theme.spacing(2)};
  min-width: 175px;

  .log {
    width: 100%;
    margin-top: -${p => p.theme.spacing(2)};
    display: flex;
    align-items: center;
    gap: ${p => p.theme.spacing(2)};
    font-size: 0.65rem;
    line-height: 0.75rem;
    padding: ${p => p.theme.spacing(1, 0, 3, 0)};
    border-bottom: 1px solid ${p => p.theme.palette.surface.fade};
  }
`;

const StyledList = styled(List)`
  padding: 0;
  > * {
    padding: 0;
    line-height: 1rem;
    padding: 2px 0;
  }
`;

type Props = {
  className?: string;
  session: Session;
  composite: CompositeMarkerLoadState;
  activeHost: Host;
  duration: number;
  markersError?: string;
  startTime: string;
  videoRef?: MutableRefObject<HTMLVideoElement>;
  hasHostGraph?: boolean;
  onRefreshMarker(): void;
  autoScroll: boolean;
  recommendations?: AnalyticRecommendation[];
};

const AttackColumn = React.memo(function AttackColumn({ marker }: { marker: MarkerExtended }) {
  if (!marker.attack_names) return null;

  return (
    <Tooltip
      arrow
      placement='top'
      title={
        <StyledList>
          {marker.attack_names.map((attack, i) => (
            <ListItem key={i}>{attack}</ListItem>
          ))}
        </StyledList>
      }
    >
      <div className='no-break'>
        {marker.attack_names?.[0]}
        <em>{' ' + (marker.attack_names.length > 1 ? ` and ${marker.attack_names.length - 1} others` : '')}</em>
      </div>
    </Tooltip>
  );
});

export default function Timeline(props: Props): JSX.Element {
  const apiRef = useGridApiRef();
  const location = useLocation();
  const { permission, defaultOrgId } = useAuth();
  const canEdit = checkContentPermission(props.session, ContentPermission.Edit);
  const canSplunk = checkTaskPermission(permission, FunctionalPermission.SplunkUIAccess);
  const { session, activeHost, markersError, recommendations } = props;
  const markersLoading = props.composite.status === Status.pending;
  const showError = !!markersError && !markersLoading;
  const markers = props.composite.markers.all;
  const [markerWithinPrecision, setMarkerWithPrecision] = React.useState<number>();
  const { palette } = useTheme();

  const handleTimeUpdate = React.useCallback(() => {
    if (props.autoScroll) {
      for (let x = 0; x < markers.length; x++) {
        const currentTime = props.videoRef.current?.currentTime;
        if (currentTime) {
          const offsetFromCurrTime = markers[x].offset - currentTime;

          // Marker icons cover a big area. Clicking on them produces
          // a fuzzy point. So lets keep our "in range" threshold wide
          // enough to account for that.
          if (offsetFromCurrTime >= -1.5 && offsetFromCurrTime < 1) {
            apiRef.current.scrollToIndexes({
              rowIndex: x,
              colIndex: 0
            });
            setMarkerWithPrecision(x);
            break;
          }
        }
      }
    }
  }, [apiRef, markers, props.autoScroll, props.videoRef]);

  React.useEffect(() => {
    if (props.videoRef && props.videoRef.current) {
      props.videoRef.current.addEventListener('timeupdate', handleTimeUpdate);
    }

    return () => {
      if (props.videoRef && props.videoRef.current)
        props.videoRef.current.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [handleTimeUpdate, props.videoRef]);

  const handleAddMarker = async (payload: RedMarkerCreationPayload) => {
    await addMarker(props.session.guid, activeHost.guid, payload, props.composite);
    props.onRefreshMarker();
  };

  const handleUpdateMarker = async (marker: RedMarkerExtended, payload: RedMarkerUpdatePayload) => {
    const hostGuid = props.session.hosts.find(host => host.id === marker.host_id)?.guid;
    await updateMarker(props.session.guid, hostGuid, marker.id, payload, props.composite);
    props.onRefreshMarker();
  };

  const handleDeleteMarker = async (marker: RedMarkerExtended) => {
    const hostGuid = props.session.hosts.find(host => host.id === marker.host_id)?.guid;
    await deleteMarker(props.session.guid, hostGuid, marker.id, props.composite);
    props.onRefreshMarker();
  };

  const renderBlueMarker = (hit: BlueMarkerExtended) => {
    return hit.lonely ? <UnvalidatedAnalyticIcon inheritBackground /> : <Icon icon={faCircle} color='blue' />;
  };

  const renderRedMarker = (marker: RedMarkerExtended, offset: number) => {
    return canEdit ? (
      <Tooltip placement='top' title='Update attack' wrap arrow>
        <RedMarkerButton marker={marker} offset={offset} startTime={props.startTime} />
      </Tooltip>
    ) : marker.lonely ? (
      <UndetectedThreatIcon inheritBackground />
    ) : (
      <Icon icon={faStar} color='error' />
    );
  };

  const renderMarkerColumn = (marker: MarkerExtended, offset: number) => {
    return isBlueMarker(marker) ? renderBlueMarker(marker) : renderRedMarker(marker, offset);
  };

  const renderRankColumn = (marker: MarkerExtended) => {
    if (isBlueMarker(marker)) {
      const firstAnalytic = marker?.analytics[0];
      const confidenceScoreDetail = getScoreDetails(defaultOrgId, firstAnalytic?.ranks);

      return <ConfidenceScoreBadge confidenceScoreDetail={confidenceScoreDetail} />;
    }
  };

  const renderSeverityColumn = (marker: MarkerExtended) => {
    if (isBlueMarker(marker)) {
      const firstAnalytic = marker?.analytics[0];
      const severity = getPreferredOrgScore(defaultOrgId, firstAnalytic?.severities, firstAnalytic?.severity);

      return <Badge badgeSignature={severity} badgeName='SEVERITY' />;
    } else {
      return <Badge badgeSignature={marker.severity || ArtifactScore.UNKNOWN} badgeName='SEVERITY' />;
    }
  };

  const renderAnalyticNameColumn = (marker: BlueMarker, isRecommendedDetection?: boolean) => (
    <>
      {isRecommendedDetection && (
        <Tooltip arrow placement='top-start' title='Recommended Detection'>
          <div>
            <Certified size={3} />
          </div>
        </Tooltip>
      )}
      <Link className='no-break' to={`${Path.Detection}/${marker.analytics[0].guid}`}>
        <Tooltip arrow wrap placement='top' title={<AnalyticPreview marker={marker} />}>
          <>{marker.analytics[0].name}</>
        </Tooltip>
      </Link>
    </>
  );

  const renderAttackNameColumn = (marker: RedMarkerExtended) => (
    <div className='no-break'>{marker?.description ? marker.description : 'Red marker name'}</div>
  );

  const renderNameColumn = (marker: MarkerExtended, isRecommendedDetection?: boolean) => {
    return isBlueMarker(marker)
      ? renderAnalyticNameColumn(marker, isRecommendedDetection)
      : renderAttackNameColumn(marker);
  };

  const hostHasGraph = (marker: MarkerExtended, session: Session): boolean => {
    let host = undefined;
    // Data is hydrated differently between red and blue markers
    if (isBlueMarker(marker)) {
      host = session.hosts.find(host => host.machine.name === marker.event?.machine_name);
    } else {
      host = session.hosts.find(host => host.id === marker.event?.host_id);
    }

    if (!host) {
      return false;
    } else {
      return host?.graph ? true : false;
    }
  };

  const findHostGuid = (marker: MarkerExtended, session: Session) => {
    let host = undefined;
    // Data is hydrated differently between red and blue markers
    if (isBlueMarker(marker)) {
      host = session.hosts.find(host => host.machine.name === marker.event?.machine_name);
    } else {
      host = session.hosts.find(host => host.id === marker.event?.host_id);
    }

    if (!host) {
      return null;
    } else {
      return host.guid;
    }
  };

  const renderActionColumnGraphButton = (marker: MarkerExtended, session: Session) => {
    // We follow the marker, not the active host (so you can view a blue marker from
    // an attacker machine's tab) but not all of the data is hydrated on the markers endpoint
    const hasGraph = hostHasGraph(marker, session);
    const hostId = findHostGuid(marker, session);
    const params: any = {
      hostname: marker.event?.machine_name,
      hostId: hostId,
      threatId: session.guid
    };
    const notVisibleIcon = (
      <Tooltip arrow wrap title={'Event not visible on process graph'} placement='top'>
        <Icon icon={faSitemap} rotation={270} color='grey' />
      </Tooltip>
    );

    if (!marker.event?.row_id || !hasGraph) {
      return notVisibleIcon;
    }

    if (marker.event?.process_id) {
      params.searchField = 'ProcessId';
      params.searchQuery = marker.event?.process_id;
    } else {
      // Marker is not a process node, so show processes near the time of the event
      const time = new Date((marker as RedMarker).timestamp).getTime();
      if (isNaN(time)) {
        return notVisibleIcon;
      }
      params.minTime = time - 5000;
      params.maxTime = time + 5000;
    }
    return (
      <Tooltip
        arrow
        title={isBlueMarker(marker) ? 'View event on process graph' : 'View attack on process graph'}
        placement='top'
      >
        <Link
          target='_blank'
          to={{
            pathname: Route.ProcessGraph,
            search: formatQueryString(params)
          }}
        >
          <Icon icon={faSitemap} rotation={270} color={palette.common.white} />
        </Link>
      </Tooltip>
    );
  };

  const renderActionColumnLogButton = (marker: MarkerExtended) => {
    if (canSplunk) {
      return <HitLog session={props.session} hit={marker} />;
    } else {
      return (
        <Tooltip arrow title={'You must become a community contributor to view event logs'} placement='top'>
          <Icon icon={faFileAlt} color='grey' />
        </Tooltip>
      );
    }
  };

  const renderActionColumnRedStarButton = (marker: MarkerExtended, offset: number) => {
    if (isBlueMarker(marker)) {
      return canEdit ? (
        <Tooltip placement='top' title='Label attack' wrap arrow>
          <RedMarkerButton newMarker marker={marker} offset={offset} startTime={props.startTime} />
        </Tooltip>
      ) : (
        <Tooltip title='Only content owners can label attacks' placement='top' arrow wrap>
          <Icon icon={faStar} color='grey' />
        </Tooltip>
      );
    } else {
      return;
    }
  };

  const renderActionColumnCreateDetectionButton = (marker: MarkerExtended, color: MarkerColor) => {
    if (MarkerColor.Red === color && checkTaskPermission(permission, FunctionalPermission.CreateAnalytic)) {
      const redMarker = marker as RedMarker;
      return (
        <TooltipConstrained arrow title={'Create Detection'} placement='top'>
          <Link
            to={
              redMarker.event?.row_id
                ? getLocation({
                    pathname: Path.IDE,
                    search: {
                      row_id: `${redMarker.event?.row_id}`,
                      hostname: activeHost.machine.name
                    }
                  })
                : getLocation({
                    pathname: Path.IDE,
                    search: {}
                  })
            }
          >
            <Icon icon={faCrosshairs} color={palette.common.white} />
          </Link>
        </TooltipConstrained>
      );
    } else {
      return <div />;
    }
  };

  const findRecommendedDetectionMarkers = (marker: MarkerExtended) => {
    return isBlueMarker(marker)
      ? recommendations?.some(recommendation => recommendation.guid == marker.analytics[0].guid)
      : false;
  };

  const markerColumns: GridColDef[] = [
    {
      field: 'time',
      headerName: 'Time',
      renderCell: p => (
        <Link
          replace={true}
          to={{
            ...location,
            search: formatQueryString({
              ...parseAnyQueryString(location.search),
              t: p.row.offset
            }),
            state: { forceSeekUpdate: true, noScroll: true }
          }}
        >
          {p.value}
        </Link>
      ),
      width: 50,
      hideSortIcons: true
    },
    {
      field: 'marker',
      headerName: 'Marker',
      renderCell: p => renderMarkerColumn(p.row.marker, p.row.offset),
      width: 70,
      hideSortIcons: true,
      align: 'center'
    },
    {
      field: 'name',
      headerName: 'Name',
      renderCell: p => renderNameColumn(p.row.marker, p.row.isRecommendedDetection),
      flex: 0.3,
      minWidth: 250,
      hideSortIcons: true
    },
    {
      field: 'attack',
      headerName: 'ATT&CK',
      renderCell: p => <AttackColumn marker={p.row.marker} />,
      flex: 0.3,
      minWidth: 200,
      hideSortIcons: true
    },
    {
      field: 'severity',
      headerName: 'Severity',
      renderCell: p => renderSeverityColumn(p.row.marker),
      minWidth: 120,
      hideSortIcons: true
    },
    {
      field: 'confidence',
      headerName: 'Confidence',
      renderCell: p => renderRankColumn(p.row.marker),
      minWidth: 120,
      hideSortIcons: true
    },
    {
      field: 'action',
      headerName: 'Actions',
      renderCell: p => (
        <div className='timeline-actions'>
          {renderActionColumnGraphButton(p.row.marker, session)}
          {renderActionColumnLogButton(p.row.marker)}
          {renderActionColumnRedStarButton(p.row.marker, p.row.offset)}
          {renderActionColumnCreateDetectionButton(p.row.marker, p.row.color)}
        </div>
      ),
      minWidth: 100,
      hideSortIcons: true
    }
  ];

  const Row = (index: number) => {
    const marker = markers[index];
    const color = marker['id'] ? MarkerColor.Red : MarkerColor.Blue;
    const offset = getDiff(marker['timestamp'] || marker['event']['timestamp'], props.startTime);
    const isRecommendedDetection = findRecommendedDetectionMarkers(marker);
    return {
      time: convertSecondsToMMss(offset),
      marker: marker,
      name: marker,
      attack: <AttackColumn marker={marker} />,
      markerIndex: index,
      offset: offset,
      isRecommendedDetection: isRecommendedDetection,
      color: color
    };
  };

  return (
    <AnalyticPreviewProvider>
      {showError && (
        <Alert severity='error'>
          <AlertTitle>Marker error</AlertTitle>
          {markersError}
        </Alert>
      )}
      <RedMarkerInterface>
        <TimelineRoot>
          {markersLoading && !apiRef.current ? (
            <TablePlaceholder />
          ) : (
            <DataGrid
              checkboxSelection={false}
              columns={markerColumns}
              rows={[...markers.map((_marker, index) => Row(index))]}
              rowHeight={48}
              getRowId={row => row.markerIndex}
              disableColumnResize
              disableColumnMenu
              disableColumnPinning
              disableColumnReorder
              disableChildrenSorting
              disableMultipleRowSelection
              getRowClassName={p =>
                classnames(
                  'Timeline-row',
                  { 'Timeline-row-recommended-detection': p.row.isRecommendedDetection },
                  { 'Timeline-row-marker-precision': p.id === markerWithinPrecision }
                )
              }
              apiRef={apiRef}
            />
          )}
          <RedMarkerDialog onAdd={handleAddMarker} onUpdate={handleUpdateMarker} onDelete={handleDeleteMarker} />
        </TimelineRoot>
      </RedMarkerInterface>
    </AnalyticPreviewProvider>
  );
}

/** Displays the name of a blue marker's analytic and loads its description text */
function AnalyticPreview({ marker }: { marker: BlueMarker }) {
  const analyticPreview = marker.analytics[0];
  const { analytic, status } = useAnalyticPreview(analyticPreview.guid);

  if (status !== Status.resolved) return <AnalyticPreviewPlaceholder name={analyticPreview.name} />;

  return (
    <AnalyticPreviewContainer>
      <strong>{analyticPreview.name}</strong>
      <div className='log'>
        <Icon icon={faCube} />
        {analytic?.logsource}
      </div>
      <p>{analytic?.description}</p>
    </AnalyticPreviewContainer>
  );
}

function AnalyticPreviewPlaceholder({ name }: { name: string }) {
  return (
    <AnalyticPreviewContainer>
      <strong>{name}</strong>
      <div className='log'>
        <Icon icon={faCube} />
        <Placeholder variant='text' width={100} height={10} />
      </div>
      <p>
        <Placeholder variant='text' width={150} height={10} />
        <Placeholder variant='text' width={100} height={10} />
        <Placeholder variant='text' width={135} height={10} />
      </p>
    </AnalyticPreviewContainer>
  );
}
