import React from 'react';

import { faFilePdf, faPen } from '@fortawesome/pro-solid-svg-icons';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import moment from 'moment';
import { useLocation } from 'react-router-dom';

import Button, { ActionIconButton } from 'snap-ui/Button';
import DoubleLineChart from 'snap-ui/Charts/DoubleLineChart';
import RiskMatrix from 'snap-ui/Charts/RiskMatrix/RiskMatrix';
import SpecifiedDomainRadarChart from 'snap-ui/Charts/SpecifiedDomainRadarChart';
import ShouldLink from 'snap-ui/Charts/components/ShouldLink';
import Grid from 'snap-ui/Grid';
import Tooltip from 'snap-ui/Tooltip';
import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

import useDemoMode from 'hooks/useDemoMode';
import useTitle from 'hooks/useTitle';

import SectionNameInput from 'module/Analytic/core/Detection/SectionNameInput';
import { ApiError } from 'module/ApiError';
import { exportToPDF } from 'module/Dashboard/core/Dashboard.helper';
import DashboardGrid from 'module/Dashboard/core/DashboardGrid';
import DateRangeSelector, { DateRange, getInitialDatesByPreset } from 'module/DateRangeSelector';
import { DetectionDeploymentStatus } from 'module/GlobalFilter/Filters/DeployedEnvironment';
import { BurgerClicker, Menu } from 'module/Layout/Artifact.widgets';

import { useAuth, useIntegrationCatalog, usePushSnack } from 'provider';

import { Catalog, Scheme, Status, useAsyncStorage } from 'storage';

import { ArtifactScore, ArtifactType, Ident } from 'types/common';

import { formatQueryString, parseAnyQueryString } from 'utilities/SearchParam';
import { formatCustomNow } from 'utilities/TimeUtils';

import ChartPanel from '../core/ChartPanel';
import { Header, WithPrintStyle } from '../core/Dashboard.style';
import { EXPORT_FORMAT } from '../core/Dashboard.util';
import { DEMO_SNACK_MESSAGE } from '../core/Dashboard.widgets';
import { CoverPage } from '../core/DashboardCoverPage';
import CapacityThreshold from './CapacityThreshold';
import {
  FullWidthPlaceHolder,
  OnHoverWrapper,
  PlaceHolderIntegration,
  Text
} from './IntegrationsBoard/IntegrationBoard.widgets';
import IntegrationsBoard from './IntegrationsBoard/IntegrationsBoard';
import {
  DEMO_ATTACK_COVERAGE,
  DEMO_COLOR_KEY,
  DEMO_RISK_MATRIX,
  DEMO_SOC_CAPACITY,
  DEMO_THRESHOLD
} from './SocDashboard.const';
import { ActionGraph } from './SocDashboard.type';
import { getAttackCoverageData, getColorKey } from './SocDashboard.util';
import useSocDashboardStats from './useSocDashboardStats';

export interface SocCapacity {
  socCapacityThreshold: number;
}

const DASHBOARD_ID = 'SOCDashboard';

const ButtonContainer = styled('div')`
  padding: ${p => p.theme.spacing(4)};
  text-align: center;

  > a:first-of-type {
    margin-right: ${p => p.theme.spacing(4)};
  }

  > button:first-of-type {
    margin-right: ${p => p.theme.spacing(4)};
  }
`;

const INITIAL_PRESET = 30;
function SocDashboard() {
  useTitle('SOC Manager Dashboard | SnapAttack');
  const [dates, setDates] = React.useState<DateRange>(() => getInitialDatesByPreset(INITIAL_PRESET));
  const [hiddenIntegrations, setHiddenIntegrations] = React.useState<Ident[]>([]);
  const { search } = useLocation();
  const pushSnack = usePushSnack();
  const { isSubscriber } = useAuth();
  const { isDemoMode } = useDemoMode();
  const { statsData, statsStatus, statsErrorProps, combinedHits, hitsByIntegration, hitsStatus, hitsErrorProps } =
    useSocDashboardStats(dates);
  const { integrations } = useIntegrationCatalog();
  const [isEditingTitle, setIsEditingTitle] = React.useState(false);
  const { data: title, setData: setTitle } = useAsyncStorage<string>(Scheme.localStorage, Catalog.soc_title, '');

  const languages = integrations.preferred_org_aware
    .filter(integration => !integration.name.includes('SnapAttack -'))
    .flatMap(integration => integration.deployment_targets?.map(target => target.id));

  const { data: socCapacity, setData: setSocCapacity } = useAsyncStorage<number>(
    Scheme.localStorage,
    Catalog.soc_capacity,
    0
  );

  const allData = statsData?.data?.['all'] || [];
  const visibleStats = React.useMemo(
    () => omit(statsData?.data, hiddenIntegrations),
    [hiddenIntegrations, statsData?.data]
  );

  const riskMatrixData = (allData?.[allData.length - 1]?.action_graph || []) as ActionGraph;
  const socCapacityData = combinedHits.map(i => {
    const date = moment(i.day).format('MM/DD/YY');
    return {
      value: i.hits,
      date
    };
  });
  const pdfExportRef = React.useRef<HTMLDivElement>(null);
  const todaysDate = formatCustomNow(EXPORT_FORMAT);
  const reportTitle = 'SOC Manager Report';

  const attackCoverageData = React.useMemo(() => {
    return getAttackCoverageData(visibleStats, integrations.preferred_org_aware);
  }, [integrations.preferred_org_aware, visibleStats]);

  const colorKey = getColorKey(visibleStats, integrations.preferred_org_aware);

  const isDataEmpty = isEmpty(visibleStats);
  const isSocDataEmpty = socCapacityData.every(d => d.value === 0);

  const displayDemoData = isDemoMode || !isSubscriber;
  React.useEffect(() => {
    if (!isSubscriber) {
      pushSnack(DEMO_SNACK_MESSAGE, 'info', 'center', 'bottom', 30000);
    }
  }, [isSubscriber, pushSnack]);

  function handleDeleteIntegration(id) {
    setHiddenIntegrations(hidden => [...hidden, id]);
  }

  const SocCapacityComponent = (
    <ChartPanel
      title={
        <Header>
          <Typography variant='h2'>SOC Capacity</Typography>
          <CapacityThreshold storeSocCapacity={setSocCapacity} socCapacity={socCapacity} />
        </Header>
      }
    >
      <DoubleLineChart
        hideLegend
        data={displayDemoData ? DEMO_SOC_CAPACITY : socCapacityData}
        threshold={displayDemoData ? DEMO_THRESHOLD : socCapacity}
      />
      <ButtonContainer className='no-print'>
        <ShouldLink
          to={
            displayDemoData
              ? null
              : {
                  pathname: '/feed',
                  search: formatQueryString({
                    ...parseAnyQueryString(search),
                    page: '1',
                    topic: ArtifactType.Analytic,
                    confidence: [ArtifactScore.MEDIUM, ArtifactScore.HIGH, ArtifactScore.HIGHEST],
                    posture: 'validatedDetections',
                    deployedStatus: DetectionDeploymentStatus.false,
                    languages: languages
                  })
                }
          }
        >
          <Button variant='outlined' disabled={displayDemoData}>
            Find Recommended Detections
          </Button>
        </ShouldLink>
        <ShouldLink
          to={
            displayDemoData
              ? null
              : {
                  pathname: '/feed',
                  search: formatQueryString({
                    ...parseAnyQueryString(search),
                    page: '1',
                    topic: ArtifactType.Analytic,
                    confidence: [ArtifactScore.LOWEST, ArtifactScore.LOW],
                    deployedStatus: DetectionDeploymentStatus.success
                  })
                }
          }
        >
          <Button variant='outlined' disabled={displayDemoData}>
            Find Tunable Detections
          </Button>
        </ShouldLink>
      </ButtonContainer>
    </ChartPanel>
  );

  return (
    <WithPrintStyle id={DASHBOARD_ID}>
      <div ref={pdfExportRef}>
        <CoverPage title={reportTitle} details={todaysDate} />
        <ApiError {...statsErrorProps} />
        <DashboardGrid>
          <Grid item xs={12} md={12} lg={12}>
            <Header>
              <Typography variant='h1' className='pdf_title'>
                {isEditingTitle ? (
                  <SectionNameInput
                    initialValue={title}
                    onSave={text => {
                      setTitle(text);
                      setIsEditingTitle(false);
                    }}
                  />
                ) : (
                  <div className='title-container'>
                    {title === '' ? 'SOC Manager Dashboard' : title}
                    <Tooltip title='Edit Dashboard Title' placement='top' arrow wrap className='pencil-button'>
                      <ActionIconButton
                        aria-label='Edit Dashboard Title'
                        icon={faPen}
                        onClick={(): void => setIsEditingTitle(true)}
                      />
                    </Tooltip>
                  </div>
                )}
              </Typography>
              <div className='Header-action'>
                <DateRangeSelector
                  dates={dates}
                  onDateRangeChange={setDates}
                  initialPreset={INITIAL_PRESET}
                  demoMode={displayDemoData}
                />
                <Menu className='no-print'>
                  <BurgerClicker
                    disabled={!isSubscriber}
                    icon={faFilePdf}
                    onClick={() =>
                      exportToPDF({
                        ref: pdfExportRef,
                        fileName: `SOC_Dashboard_${todaysDate}.pdf`
                      })
                    }
                    title='Export PDF'
                  />
                </Menu>
              </div>
            </Header>
          </Grid>
        </DashboardGrid>

        {(statsStatus === Status.idle || statsStatus === Status.pending) && !displayDemoData ? (
          <>
            <PlaceHolderIntegration />
            <FullWidthPlaceHolder />
          </>
        ) : (
          <>
            <IntegrationsBoard
              demoMode={displayDemoData}
              hitsByIntegration={hitsByIntegration}
              integrations={visibleStats}
              handleDeleteIntegration={handleDeleteIntegration}
            />
            <DashboardGrid>
              <Grid item xs={12} md={6} lg={6}>
                <ChartPanel
                  title='ATT&CK Coverage'
                  titleTooltip={
                    'Measures, per tactic, the number of MITRE ATT&CK techniques and subtechniques that have one or more deployed detections'
                  }
                >
                  <SpecifiedDomainRadarChart
                    data={displayDemoData ? DEMO_ATTACK_COVERAGE : attackCoverageData}
                    colorKey={displayDemoData ? DEMO_COLOR_KEY : colorKey}
                  />
                </ChartPanel>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <ChartPanel
                  title='Deployed Detections by Use Case'
                  titleTooltip={
                    'Displays the suggested use case for deployed detections based on their severity and confidence values. Deployed detections that have an unknown severity or confidence will not appear on this chart.'
                  }
                >
                  <RiskMatrix data={displayDemoData ? DEMO_RISK_MATRIX : riskMatrixData} />
                </ChartPanel>
              </Grid>
              {(hitsStatus === Status.idle || hitsStatus === Status.pending) && !displayDemoData ? (
                <>
                  <FullWidthPlaceHolder />
                </>
              ) : (isDataEmpty || isSocDataEmpty) && !displayDemoData ? (
                <OnHoverWrapper
                  xs={12}
                  md={12}
                  lg={12}
                  minHeight={550}
                  onHoverComponent={
                    <ChartPanel title='SOC Capacity'>
                      <Text>Detection hits are measured from recurring confidence tailoring jobs</Text>
                    </ChartPanel>
                  }
                >
                  {SocCapacityComponent}
                </OnHoverWrapper>
              ) : (
                <Grid item xs={12} md={12} lg={12}>
                  <ApiError {...hitsErrorProps} />
                  {SocCapacityComponent}
                </Grid>
              )}
            </DashboardGrid>
          </>
        )}
      </div>
    </WithPrintStyle>
  );
}

export default SocDashboard;
