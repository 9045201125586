import React from 'react';

import zod from 'zod';

import { Option } from 'snap-ui/Autocomplete';

import { Discriminator } from 'module/Tag';
import useTagOptions from 'module/Tag/useTagOptions';

import { ArtifactType } from 'types/common';
import { Ops } from 'types/filter';

import { FilterConfig } from '../GlobalFilter.type';
import InclusiveAutocomplete from './InclusiveAutocomplete';

type MitreKeys = {
  attack: string[];
  attackOp: Ops;
};

type MitreFilterProps = {
  onChange(values: Partial<MitreKeys>): void;
  values: MitreKeys;
};

function MitreFilter({ onChange, values }: MitreFilterProps): JSX.Element {
  const { attack, attackOp } = values;

  const { options, search, searching } = useTagOptions(Discriminator.Attack, { includeSigma: false });

  const handleValueChange = (option: Option[]) => {
    const payload: Partial<MitreKeys> = { attack: option.map(o => o.value) };
    if (option.length === 0) payload.attackOp = undefined;
    onChange(payload);
  };

  const handleOperatorChange = (attackOp: Ops) => {
    onChange({ attackOp });
  };

  return (
    <InclusiveAutocomplete
      title='By MITRE ATT&CK Mapping'
      name='mitre_tags_dropdown'
      option={options}
      value={attack}
      onValueChange={handleValueChange}
      onOperatorChange={handleOperatorChange}
      operator={attackOp}
      onSearch={search}
      searching={searching}
    />
  );
}

function toQuery(values: MitreKeys) {
  if (!values.attack?.length) return;
  return {
    field: 'attack_ancestors_names',
    op: values.attackOp,
    value: values.attack.map(decodeURIComponent)
  };
}

function toAttackTagQuery(values: MitreKeys) {
  if (!values.attack?.length) return;
  const fields = ['name', 'parents.name', 'parents.parents.name'];
  return {
    op: Ops.or,
    items: fields.map(field => ({
      field,
      op: values.attackOp,
      value: values.attack.map(decodeURIComponent)
    }))
  };
}

const fromQuery = zod
  .object({
    field: zod.literal('attack_ancestors_names'),
    op: zod.nativeEnum(Ops),
    value: zod.array(zod.string())
  })
  .transform(query => ({
    attack: query.value,
    attackOp: query.op
  }));

const fromAttackTagQuery = zod
  .object({
    op: zod.literal(Ops.or),
    items: zod.array(
      zod.object({
        field: zod.union([zod.literal('name'), zod.literal('parents.name'), zod.literal('parents.parents.name')]),
        op: zod.nativeEnum(Ops),
        value: zod.array(zod.string())
      })
    )
  })
  .transform(query => ({
    attack: query.items[0].value,
    attackOp: query.items[0].op
  }));

const MitreFilterConfig: FilterConfig<MitreKeys> = {
  defaults: { default: () => ({ attack: [], attackOp: Ops.any }) },
  supportedTopics: [
    ArtifactType.Analytic,
    ArtifactType.AttackTag,
    ArtifactType.Collection,
    ArtifactType.Intel,
    ArtifactType.Marker,
    ArtifactType.Session,
    ArtifactType.AttackScript
  ],
  component: MitreFilter,
  toQuery: { default: toQuery, [ArtifactType.AttackTag]: toAttackTagQuery },
  fromQuery: { default: fromQuery, [ArtifactType.AttackTag]: fromAttackTagQuery }
};
export default MitreFilterConfig;
