import React from 'react';

import classnames from 'classnames';

import Button from 'snap-ui/Button';
import Fade from 'snap-ui/Fade';

import { Alert, AlertTitle } from './ApiError.style';
import { ErrorProps } from './ApiError.type';

export const testId = 'apierror-alert';

export default function ApiError({ className, title, messages = [], defaultShowAll = false }: ErrorProps): JSX.Element {
  const [showAllMessages, setShowAllMessages] = React.useState(defaultShowAll);
  const toggleTitle = showAllMessages ? 'Hide' : 'Show';

  function toggleMessages() {
    setShowAllMessages(v => !v);
  }

  if (!title && !messages.length) return null;

  return (
    <Fade in timeout={500}>
      <Alert className={classnames('ApiError', className)} severity='error' data-testid={testId}>
        {(title || messages.length > 1) && (
          <AlertTitle>
            {title || 'There was a problem'}
            {messages.length > 1 && (
              <Button color='error' size='small' variant='text' onClick={toggleMessages}>
                {toggleTitle}
              </Button>
            )}
          </AlertTitle>
        )}
        {!!messages.length &&
          (messages.length === 1 ? (
            messages[0]
          ) : showAllMessages ? (
            <ul>
              {messages.map((message, idx) => (
                <li key={idx}>{message}</li>
              ))}
            </ul>
          ) : null)}
      </Alert>
    </Fade>
  );
}
