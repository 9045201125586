import { Host } from 'module/Session/Session.type';

import { ArtifactScore } from 'types/common';

import { Analytic } from './analytic';
import { Ident } from './common';

export type MarkerEvent = {
  id: Ident;
  host_id: number;
  raw: string;
  source: string;
  sigma_product?: string;
  sigma_service?: string;
  sigma_category?: string;
  timestamp: string;
  process_id: string;
  host: Host;
  row_id: string;
  machine_name?: string; // blue only
};

export type RedMarker = {
  description: string;
  id: Ident;
  host_id: Ident;
  host: Host;
  /**
   * Maybe returned when marker is created through process graph
   */
  event?: MarkerEvent;
  timestamp: string;
  /**
   * from the host video start time
   */
  offset: number;
  severity: ArtifactScore;
  attack_names: string[];
  actor_names: string[];
  software_names: string[];
  vulnerability_names: string[];
};

export type Page<T> = {
  items: T[];
  /** the current page number */
  page: number;
  /** max number of items that was requested */
  size: number;
  /** total number of items */
  total: number;
};

export type BlueMarkerAnalytic = Pick<
  Analytic,
  'name' | 'guid' | 'rank' | 'ranks' | 'severity' | 'severities' | 'organization_id' | 'attack_names'
>;

type ExtendedMarker<M extends Marker> = M & { lonely: boolean };

// BlueMarker ID is === analytic_compilation_id + row_id
export type BlueMarker = {
  analytic_compilation_id: number;
  row_id: string;
  event: MarkerEvent;
  analytics: BlueMarkerAnalytic[];
  /**
   * from the host video start time
   */
  offset: number;
  attack_names: string[];
};
export type BlueMarkerExtended = ExtendedMarker<BlueMarker>;
export type RedMarkerExtended = ExtendedMarker<RedMarker>;

export function isRedMarker(redMarker: MarkerExtended): redMarker is RedMarkerExtended;
export function isRedMarker(redMarker: Marker): redMarker is RedMarker {
  return 'id' in (redMarker ?? {});
}

export type CompositeMarker = {
  red: RedMarker[];
  blue: BlueMarker[];
};

export interface CombinedCompositeMarker extends CompositeMarker {
  red: RedMarkerExtended[];
  blue: BlueMarkerExtended[];
  all: MarkerExtended[];
  unfilteredTotalByHost?: number;
  unfilteredTotal?: number;
}

export type MarkerExtended = RedMarkerExtended | BlueMarkerExtended;
export type Marker = RedMarker | BlueMarker;
